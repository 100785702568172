/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import { useToast } from '@chakra-ui/react'

import { baseStatsApiURL_NO_CACHE } from '@common/config'
import { FixtureLineup } from '@common/types'

import { useUser } from '../queries/useUser'

export function useCreateNewLineup() {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const { userData } = useUser()

    const toast = useToast()

    const postData = async (data: FixtureLineup) => {
        setIsError(false)

        setIsLoading(true)

        if (userData?.accessToken) {
            try {
                const response = await fetch(`${baseStatsApiURL_NO_CACHE}/default/data-provider/api/save-lineup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authentication: userData?.accessToken,
                    },
                    body: JSON.stringify(data),
                })

                if (response.status === 200) {
                    toast({
                        position: 'top',
                        title: 'New Lineup successfully created',
                        description: 'It will be updated in few seconds',
                        status: 'success',
                    })
                } else {
                    setIsError(true)

                    toast({ position: 'top', title: 'Lineup creation failed, please try again!', status: 'error' })
                }
            } catch (error) {
                setIsError(true)
            }
        } else {
            toast({
                position: 'top',
                description: 'Missing user access token!',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

        setIsLoading(false)
    }

    return { postData, isLoading, isError }
}
