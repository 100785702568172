import { useCallback, useEffect, useMemo } from 'react'
import { useToast } from '@chakra-ui/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchAuthSession, fetchUserAttributes, signInWithRedirect, signOut } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'

import { useIsWindowFocused } from '@hooks/useIsWindowFocused'
import { useNetworkState } from '@hooks/useNetworkState'

const TOKEN_REMAINING_TIME_THRESHOLD = 300

export function useUser() {
    const network = useNetworkState()
    const isFocused = useIsWindowFocused()
    const toast = useToast()
    const queryClient = useQueryClient()

    const { data: accessToken, isLoading } = useQuery({
        queryKey: ['user', 'accessToken'],
        queryFn: () => fetchAuthSession(),
        select: tokenData => ({
            accessToken: tokenData?.tokens?.accessToken?.toString(),
            tokenExpiry: tokenData?.tokens?.accessToken?.payload?.exp ?? 0,
            cognitoGroups: tokenData?.tokens?.accessToken?.payload?.['cognito:groups'] as string[],
        }),
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchOnMount: true,
        staleTime: 2 * 60 * 1000, // 2 minutes
        retry: 0,
    })

    const { data: userAttributes } = useQuery({
        queryKey: ['user', 'userAttributes'],
        queryFn: () => fetchUserAttributes(),
        enabled: !!accessToken?.accessToken,
        staleTime: 2 * 60 * 1000, // 2 minutes
    })

    const login = useCallback(
        async (provider: string) => {
            try {
                await signInWithRedirect({
                    provider: {
                        custom: provider,
                    },
                })
            } catch (error) {
                toast({
                    position: 'top',
                    description: `Error authenticating user: ${error}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
        [signInWithRedirect, toast],
    )

    const refreshToken = useCallback(async () => {
        try {
            await fetchAuthSession({ forceRefresh: true })
        } catch (error) {
            queryClient.removeQueries({ queryKey: ['user'] })

            toast({
                position: 'top',
                description: `Error refreshing user token: ${error}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            queryClient.invalidateQueries({ queryKey: ['user', 'accessToken'] })
        }
    }, [fetchAuthSession, queryClient, toast])

    useEffect(() => {
        const authUnsubscribe = Hub.listen('auth', async ({ payload }) => {
            if (payload) {
                switch (payload.event) {
                    case 'tokenRefresh':

                    case 'signInWithRedirect':

                    case 'signedIn':
                        await queryClient.invalidateQueries({ queryKey: ['user'] })

                        break

                    case 'tokenRefresh_failure':

                    case 'signInWithRedirect_failure':
                        queryClient.removeQueries({ queryKey: ['user'] })

                        toast({
                            position: 'top',
                            description: 'Error authenticating user',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })

                        break

                    case 'signedOut':
                        queryClient.removeQueries({ queryKey: ['user'] })

                        toast({
                            position: 'top',
                            description: 'Error logging out user',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })

                        break

                    default:
                        break
                }
            }
        })

        return () => {
            authUnsubscribe()
        }
    }, [])

    useEffect(() => {
        if (!accessToken) {
            return
        }

        const timeToTimeout = accessToken.tokenExpiry * 1000 - new Date().getTime()

        if (timeToTimeout <= TOKEN_REMAINING_TIME_THRESHOLD) {
            refreshToken()
        }
    }, [accessToken, isFocused, network?.online])

    const userData = useMemo(
        () => ({
            email: userAttributes?.email,
            accessToken: accessToken?.accessToken,
            cognitoGroups: accessToken?.cognitoGroups,
            refreshToken,
        }),
        [userAttributes, accessToken, refreshToken],
    )

    return {
        userData,
        isAuthenticated: !!accessToken?.accessToken?.length,
        isLoading,
        login,
        logout: signOut,
    }
}
