/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import { Flex, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react'

import { NewLineup } from '@common/types'

import { Player } from '../Player'
import { Droppable } from './Droppable'
import { EmptyPlayer } from './EmptyPlayer'

interface SubstitutionsEditorProps {
    lineup: NewLineup
    isHomeTeam: boolean
    currentActivePlayer: any
    handleEditPlayer: (player) => void
    handleAddPlayer: (position) => void
    handleLineupChanges: (lineup: NewLineup) => void
    showNoFormation: boolean
}

export function LineupListEditor({
    lineup,
    isHomeTeam,
    currentActivePlayer,
    handleEditPlayer,
    handleAddPlayer,
    handleLineupChanges,
}: SubstitutionsEditorProps) {
    const [activeDroppable, setActiveDroppable] = useState<any | null>(null)
    const onRemovePlayer = (rowIndex: number, columnIndex?: number) => {
        if (columnIndex !== undefined) {
            const newLineup = [...lineup]
            newLineup[rowIndex][columnIndex] = { starter: true }

            handleLineupChanges(newLineup)
        }
    }

    const getPlayerZIndex = (player, droppableId) => {
        switch (true) {
            case currentActivePlayer?.name === player.name:
                return 12

            case activeDroppable?.id === droppableId:
                return 0

            default:
                return 10
        }
    }

    const handlePlayerClick = player => {
        handleEditPlayer(player)
    }

    const handleEmptyPlayerClick = position => {
        handleAddPlayer(position)
    }

    return (
        <Flex position="relative" minW={300}>
            <Wrap bg="sp.background" borderRadius="16" borderWidth="1px" p={5} pb={14} spacing={3} overflow="visible">
                {lineup.map((row, rowIndex) =>
                    row.map((player, columnIndex) => (
                        <WrapItem key={columnIndex} flexDirection="row-reverse" pl="2" pr="2">
                            <Droppable
                                id={`droppable-${columnIndex}-${rowIndex}`}
                                data={{ colInd: columnIndex, rowInd: rowIndex }}
                                key={`droppable-${columnIndex}-${rowIndex}`}
                                setActiveDroppable={setActiveDroppable}
                            >
                                {player?.name ? (
                                    <VStack
                                        zIndex={getPlayerZIndex(player, `droppable-${columnIndex}-${rowIndex}`)}
                                        spacing={0.5}
                                        alignItems="center"
                                        w="3.8rem"
                                        h="4.5rem"
                                        position="relative"
                                        key={`player-${columnIndex}-${rowIndex}`}
                                    >
                                        <Player
                                            activeDroppable={activeDroppable}
                                            droppableId={`droppable-${columnIndex}-${rowIndex}`}
                                            player={player}
                                            variant="dark"
                                            isHomeTeam={isHomeTeam}
                                            isGoalKeeper={rowIndex === 0 && columnIndex === 0}
                                            currentActivePlayer={currentActivePlayer}
                                            onDeletePlayerClick={onRemovePlayer}
                                            colInd={columnIndex}
                                            rowInd={rowIndex}
                                            onPlayerClick={handlePlayerClick}
                                        />
                                    </VStack>
                                ) : (
                                    <EmptyPlayer
                                        id={`droppable-${columnIndex}-${rowIndex}`}
                                        activeDroppable={activeDroppable}
                                        colInd={columnIndex}
                                        rowInd={rowIndex}
                                        onEmptyPlayerClick={handleEmptyPlayerClick}
                                    />
                                )}
                            </Droppable>
                        </WrapItem>
                    )),
                )}
            </Wrap>
            <Text
                position="absolute"
                left="0.5rem"
                bottom="0.5rem"
                bg="rgba(0,0,0,0.4)"
                color="sp.white"
                py={1}
                px={4}
                borderRadius="full"
                fontSize="xs"
                fontWeight="semibold">
                No formation
            </Text>
        </Flex>
    )
}
