import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Container, Flex, FlexProps } from '@chakra-ui/react'
import { Outlet, useMatchRoute } from '@tanstack/react-router'

import { Login } from '@components/Login'

import { useUser } from '@queries/useUser'

export function Main(props: FlexProps) {
    const { isAuthenticated } = useUser()
    const matchRoute = useMatchRoute()

    if (isAuthenticated && matchRoute({ to: '/' })) {
        return (
            <Flex as="main" role="main" direction="column" flex="1" p={4} {...props}>
                <Container flex="1" maxW="container.xxl" bg="white" p={16} borderRadius="16" boxShadow="lg">
                    <Box minH="lg">
                        <Alert status="info" flexDirection="column">
                            <AlertIcon />
                            <AlertTitle>No editor selected!</AlertTitle>
                            Please select an editor to continue.
                            <AlertDescription>
                                @TODO: Should we redirect to first available editor automatically, based on roles?
                            </AlertDescription>
                        </Alert>
                    </Box>
                </Container>
            </Flex>
        )
    }

    return (
        <Flex as="main" role="main" direction="column" p={{ base: 0, lg: 4 }} flex="1" {...props}>
            <Container
                flex="1"
                maxW="container.xxl"
                bg="white"
                p={{ base: 4, xl: 16 }}
                pt={8}
                borderRadius="16"
                boxShadow="lg">
                <Box minH="lg">{isAuthenticated ? <Outlet /> : <Login />}</Box>
            </Container>
        </Flex>
    )
}
