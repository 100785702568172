import { SVGProps } from 'react'

export default function SvgPlus(props: SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                d="M12.75 5.929a.75.75 0 0 0-1.5 0h1.5zm5.32 6.821a.75.75 0 1 0 0-1.5v1.5zm-12.14-1.5a.75.75 0 0 0 0 1.5v-1.5zm5.32 6.821a.75.75 0 0 0 1.5 0h-1.5zM12.75 12V5.929h-1.5V12h1.5zm-.75.75h6.072v-1.5H12v1.5zm0-1.5H5.93v1.5H12v-1.5zm-.75.75v6.071h1.5V12h-1.5z"
                fill="currentColor"
            />
        </svg>
    )
}
