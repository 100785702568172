/* eslint-disable no-nested-ternary */
import { useState } from 'react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { chakra, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'

export type DataTableProps<Data extends object> = {
    data: Data[]
    columns: ColumnDef<Data, any>[]
    onRowClick?: (data: Data) => void
    selectedRow?: string | number | null
    defaultSorting?: SortingState
}

export function DataTable<Data extends object>({
    data,
    columns,
    onRowClick,
    selectedRow,
    defaultSorting = [],
}: DataTableProps<Data>) {
    const [sorting, setSorting] = useState<SortingState>(defaultSorting)

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    })

    return (
        <Table __css={{ tableLayout: 'auto', width: 'full' }} variant="simple">
            <Thead position="sticky" top={0} zIndex={10} bgColor="white">
                {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <Th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                                {flexRender(header.column.columnDef.header, header.getContext())}

                                <chakra.span pl="4">
                                    {header.column.getIsSorted() ? (
                                        header.column.getIsSorted() === 'desc' ? (
                                            <TriangleDownIcon aria-label="sorted descending" />
                                        ) : (
                                            <TriangleUpIcon aria-label="sorted ascending" />
                                        )
                                    ) : null}
                                </chakra.span>
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map(row => (
                    <Tr
                        key={row.id}
                        onClick={() => onRowClick?.(row.original)}
                        cursor="pointer"
                        style={{
                            backgroundColor:
                                (row.original as Data & { id: number }).id === selectedRow
                                    ? 'var(--chakra-colors-teal-100)'
                                    : 'initial',
                        }}
                    >
                        {row.getVisibleCells().map(cell => (
                            <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}
