import { CreateToastFnReturn, useToast } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'

import { baseStatsApiURL_NO_CACHE } from '@common/config'

import { useUser } from '../queries/useUser'

type Formations = {
    formations: string[]
}

async function fetchData(accessToken?: string, toast?: CreateToastFnReturn) {
    if (!accessToken) {
        throw new Error('No access token provided')
    }

    const response = await fetch(`${baseStatsApiURL_NO_CACHE}/default/data-provider/api/formations`, {
        headers: {
            Authentication: accessToken,
        },
    })

    const json = await response.json()

    if (response.status !== 200) {
        toast?.({
            position: 'top',
            description: 'Error fetching formations!',
            status: 'error',
            duration: 9000,
            isClosable: true,
        })

        throw new Error(json?.message ?? json?.error ?? '¯\\_(ツ)_/¯')
    }

    return json as Formations
}

export function useFormations() {
    const { userData } = useUser()

    const toast = useToast()

    const {
        data: formations,
        isError,
        isLoading,
    } = useQuery<Formations>({
        queryKey: ['formations', userData.accessToken, toast],
        queryFn: () => fetchData(userData.accessToken, toast),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    })

    return { formations, isLoading, isError }
}
