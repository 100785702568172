import { SVGProps } from 'react'

export default function SvgShield(props: SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
            <circle cx={250} cy={250} r={250} fill="#F1EFF4" />
            <path
                d="M379.978 168.054c0 193.543-130 241.934-130 241.934s-130-58.068-130-241.934l130-58.066 130 58.066z"
                fill="#CBC9CF"
            />
        </svg>
    )
}
