import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react'
import { useDroppable } from '@dnd-kit/core'

type DroppableProps = {
    children: ReactNode
    id: string
    data: any
    setActiveDroppable?: Dispatch<SetStateAction<any | null>>
}
export function Droppable({ id, data, children, setActiveDroppable }: DroppableProps) {
    const { setNodeRef, over } = useDroppable({
        id,
        data,
    })

    useEffect(() => {
        if (setActiveDroppable) {
            setActiveDroppable(over)
        }
    }, [over])

    return <div ref={setNodeRef}>{children}</div>
}
