/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react'

import { editorsList, StateEnum, TEditorsList } from '@common/types'

import { useCompetitionsListData } from '@queries/useCompetitionsListData'

export const possibleCompetitionFilters = { PERSONAL: 'personal', TOP: 'top' } as const

export type TPossibleCompetitionFilters = (typeof possibleCompetitionFilters)[keyof typeof possibleCompetitionFilters]

type CompetitionListDataArgs = {
    checkedCompetitions: TPossibleCompetitionFilters[]
    editor: TEditorsList
    isCompleted?: boolean
}

export const LineupFeatureTypes = {
    FEATURETYPE_LINEUP: 1,
} as const

export function useCompetitionListData({ checkedCompetitions, editor, isCompleted = false }: CompetitionListDataArgs) {
    const { competitionIds, dataQualityMatches } = useCompetitionsListData(editor)

    const competitionIdsFilter = useMemo(
        () =>
            checkedCompetitions.reduce((combinedCompetitions: string[], checkboxValue) => {
                if (competitionIds[checkboxValue]) {
                    return combinedCompetitions.concat(competitionIds[checkboxValue])
                }

                return combinedCompetitions
            }, []),
        [checkedCompetitions, competitionIds],
    )

    const competitionMatches = useMemo(() => {
        const allFiltersCompetitionIds = [...competitionIds.personal, ...competitionIds.top]
        const metaKey = `${editor.replace('-', '_')}_editor_meta`

        return (
            dataQualityMatches
                ?.filter(event => {
                    const metaDataState = event[metaKey]?.state

                    if (isCompleted) {
                        return metaDataState === StateEnum.COMPLETED
                    }

                    return metaDataState !== StateEnum.COMPLETED
                })
                ?.filter(event => {
                    const isLineupEditor = editor === editorsList.LINEUP_EDITOR
                    const hasLineup =
                        event.features.includes(LineupFeatureTypes.FEATURETYPE_LINEUP) &&
                        event.lineups_editor_meta?.state !== StateEnum.IN_PROGRESS

                    const isNotInAllFilteredCompetitions = !allFiltersCompetitionIds.includes(event.competition?.id)

                    if (isCompleted && competitionIdsFilter.length === 0) {
                        return isNotInAllFilteredCompetitions
                    }

                    if (competitionIdsFilter?.includes(event.competition?.id)) {
                        return isLineupEditor ? !hasLineup : true
                    }

                    return false
                })
                .map(filteredEvent => {
                    const eventMetaData = filteredEvent[metaKey]

                    return {
                        competition_name: `${filteredEvent.category?.country_code} - ${filteredEvent.competition?.name}`,
                        match_name: `${filteredEvent.team1.name} - ${filteredEvent.team2.name}`,
                        match_id: `br:match:${filteredEvent.id.split(':').pop()}`,
                        match_time: filteredEvent.date,
                        meta_data: eventMetaData ?? {},
                    }
                }) ?? []
        )
    }, [dataQualityMatches, competitionIdsFilter, competitionIds, editor, isCompleted])

    return {
        competitionMatches,
    }
}
