import { useMemo, useState } from 'react'
import { Alert, AlertDescription, AlertIcon, Box, Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react'
import { useRouter } from '@tanstack/react-router'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'

import { CollapsableToDrawer } from '@components/CollapsableToDrawer'
import { DataTable } from '@components/DataTable/DataTable'
import { LineupsMetaData } from '@components/MatchMetaData/LineupsMetaData'
import { MatchEventsMetaData } from '@components/MatchMetaData/MatchEventsMetaData'

import {
    possibleCompetitionFilters,
    TPossibleCompetitionFilters,
    useCompetitionListData,
} from '@hooks/useCompetitionListData'
import { useSearchState } from '@hooks/useSearchState'

import { CompetitionListMatch, TEditorsList } from '@common/types'

const columnHelper = createColumnHelper<CompetitionListMatch>()

type CompetitionListProps = {
    editor: TEditorsList
    isCompleted?: boolean
}

export function CompetitionTableWithFilters({ editor, isCompleted = false }: CompetitionListProps) {
    const [searchState, setSearchState] = useSearchState()

    const router = useRouter()

    const [checkedCompetitions, setCheckedCompetitions] = useState<TPossibleCompetitionFilters[]>(() => {
        switch (searchState?.filter) {
            case 'all':
                return [possibleCompetitionFilters.PERSONAL, possibleCompetitionFilters.TOP]

            case possibleCompetitionFilters.PERSONAL:
                return [possibleCompetitionFilters.PERSONAL]

            case possibleCompetitionFilters.TOP:
                return [possibleCompetitionFilters.TOP]

            default:
                return [possibleCompetitionFilters.PERSONAL]
        }
    })

    const { competitionMatches } = useCompetitionListData({ checkedCompetitions, editor, isCompleted })

    const columns = useMemo(
        () => [
            columnHelper.accessor('competition_name', {
                cell: value => (
                    <Box as="span" fontSize="sm">
                        {value.getValue()}
                    </Box>
                ),
                header: 'Competition',
            }),
            columnHelper.accessor('match_name', {
                cell: value => (
                    <Box as="span" fontSize="sm">
                        <Box as="span" color="grey" pe={2} fontSize="xs" onClick={event => event.stopPropagation()}>
                            {value.row.original.match_id.split(':').pop()}
                        </Box>
                        {value.getValue()}
                    </Box>
                ),
                header: `Match (${competitionMatches.length})`,
            }),
            columnHelper.accessor('match_time', {
                cell: value => (
                    <Box as="span" fontSize="sm">
                        {format(new Date(value.getValue()), 'HH:mm')}
                        <Box as="span" ps={2} color="grey">
                            {format(new Date(value.getValue()), 'yyyy-MM-dd')}
                        </Box>
                    </Box>
                ),
                header: 'Time',
            }),
            columnHelper.accessor('meta_data', {
                cell: value => (
                    <CollapsableToDrawer>
                        {editor === 'lineups' ? (
                            <LineupsMetaData
                                matchId={value.row.original.match_id}
                                metaData={value.getValue()}
                                hideTitles
                            />
                        ) : (
                            <MatchEventsMetaData
                                matchId={value.row.original.match_id}
                                hideTitles
                                metaData={value.getValue()}
                            />
                        )}
                    </CollapsableToDrawer>
                ),
                header: 'Meta',
            }),
        ],
        [competitionMatches.length],
    )

    const getSearchState = (checkedCompetitionsList: TPossibleCompetitionFilters[]) => {
        switch (true) {
            case checkedCompetitionsList.includes(possibleCompetitionFilters.PERSONAL) &&
                checkedCompetitionsList.includes(possibleCompetitionFilters.TOP):
                return 'all'

            case checkedCompetitionsList.includes(possibleCompetitionFilters.PERSONAL):
                return possibleCompetitionFilters.PERSONAL

            case checkedCompetitionsList.includes(possibleCompetitionFilters.TOP):
                return possibleCompetitionFilters.TOP

            default:
                return undefined
        }
    }

    const handleCheckGroupChange = (values: TPossibleCompetitionFilters[]) => {
        setCheckedCompetitions(values)

        setSearchState({ filter: getSearchState(values) })
    }

    const handleRowClick = rowEvent => {
        router.navigate({
            to: `/editors/${editor}`,
        })

        setSearchState({ ...searchState, ...{ matchId: rowEvent.match_id } })
    }

    return (
        <div>
            <CheckboxGroup onChange={handleCheckGroupChange} defaultValue={checkedCompetitions}>
                <Stack position="sticky" spacing={5} direction="row" m={4}>
                    <Checkbox value={possibleCompetitionFilters.PERSONAL}>My competitions</Checkbox>
                    <Checkbox value={possibleCompetitionFilters.TOP}>Top competitions</Checkbox>
                </Stack>
            </CheckboxGroup>
            {competitionMatches.length ? (
                <DataTable<CompetitionListMatch>
                    columns={columns}
                    defaultSorting={[{ id: 'match_time', desc: false }]}
                    data={competitionMatches}
                    selectedRow={null}
                    onRowClick={handleRowClick}
                />
            ) : (
                <Alert status="info" m={6}>
                    <AlertIcon />
                    <Box>
                        <AlertDescription>Sorry, no events found for your selection</AlertDescription>
                    </Box>
                </Alert>
            )}
        </div>
    )
}
