import { Image, ImageProps } from '@chakra-ui/react'

interface EntityImageProps {
    entityId?: unknown
}

export function EntityImage({ entityId, ...rest }: EntityImageProps & ImageProps) {
    // @TODO: Handle entityId & image URL
    const url = ''

    return <Image alt="Entity image" src={url} {...rest} />
}
