/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import {
    Box,
    Container,
    Flex,
    HStack,
    IconButton,
    Input,
    Modal,
    ModalOverlay,
    Spinner,
    Text,
    useBreakpointValue,
    useDisclosure,
    useToast,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'

import { useTeamSquad } from '@hooks/useTeamSquad'

import { Player } from '../Player'
import { CurrentActivePlayer, TPlayer } from '@/common/types'
import { AddPlayerModal, TPlayerModalProps } from '@/components/LineupEditor/AddPlayerModal'

type SquadListProps = {
    teamId?: string | null
    isHomeTeam: boolean
    takenPlayers?: (TPlayer | null)[]
    takenShirtNumbers: number[]
    currentActivePlayer: any
    addedSquadPlayers: TPlayer[]
    setAddedSquadPlayers: Dispatch<SetStateAction<TPlayer[]>>
}

export function SquadList({
    teamId,
    isHomeTeam,
    takenShirtNumbers,
    currentActivePlayer,
    addedSquadPlayers,
    setAddedSquadPlayers,
    takenPlayers,
}: SquadListProps) {
    const [search, setSearch] = useState('')
    const [currentEditPlayer, setCurrentEditPlayer] = useState<CurrentActivePlayer>()

    const { isOpen: isModalOpen, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure()
    const modalSize = useBreakpointValue({ base: 'full', md: 'md' })

    const { playersList, isLoading: isPlayerListLoading } = useTeamSquad(teamId ?? undefined)

    const toast = useToast()

    const sortPlayers = playerList =>
        playerList?.toSorted((a, b) => {
            if (a.shirt_number === '-') {
                return 1
            }

            if (b.shirt_number === '-') {
                return -1
            }

            return a.shirt_number - b.shirt_number
        })

    const [freePlayers, setFreePlayers] = useState<TPlayer[]>(() => sortPlayers(playersList))

    const checkIfPlayerIsTheSame = (playerName1, playerName2, id1, id2) =>
        id1 && id2 ? playerName1 === playerName2 || id1 === id2 : playerName1 === playerName2

    useEffect(() => {
        const filteredPlayersList = playersList?.filter(
            player =>
                !addedSquadPlayers.some(addedPlayer =>
                    checkIfPlayerIsTheSame(addedPlayer.name, player.name, addedPlayer.id, player.id),
                ),
        )

        if (!currentActivePlayer) {
            const allPlayers = filteredPlayersList?.concat(addedSquadPlayers)

            setFreePlayers(
                sortPlayers(
                    allPlayers?.filter(
                        player =>
                            !takenPlayers?.find(p =>
                                checkIfPlayerIsTheSame(p?.name, player?.name, p?.id, player?.id),
                            ) &&
                            (player?.name?.toLowerCase().includes(search?.toLowerCase()) ||
                                player?.id?.toLowerCase().includes(search?.toLocaleLowerCase())),
                    ),
                ),
            )
        }
    }, [takenPlayers, search, isPlayerListLoading, addedSquadPlayers])

    const handleAddNewPlayer = () => {
        onOpenModal()
    }

    const setFreePlayer = (player, index) => {
        const currentFreePlayers = [...freePlayers]

        currentFreePlayers[index] = player

        setFreePlayers(currentFreePlayers)
    }

    const addPlayerToSquadList = (playerData: TPlayerModalProps) => {
        const currentAddedSquadPlayers = [...addedSquadPlayers]

        currentAddedSquadPlayers.push({ ...playerData.player })

        setAddedSquadPlayers(currentAddedSquadPlayers)

        handleModalClose()
    }

    const handleAddNewSquadPlayer = (playerData: TPlayerModalProps) => {
        if (playerData?.position?.index !== undefined) {
            setFreePlayer(playerData?.player, playerData?.position.index)

            handleModalClose()
        } else if (addedSquadPlayers.some(player => player.name === playerData.player?.name)) {
            toast({
                position: 'top',
                description: 'Player with the same name already exists!',
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        } else {
            addPlayerToSquadList(playerData)

            handleModalClose()
        }
    }

    const handlePlayerClick = player => {
        setCurrentEditPlayer({ ...player.player, ...player.position })

        onOpenModal()
    }

    const handleModalClose = () => {
        onCloseModal()

        setCurrentEditPlayer(undefined)
    }

    return (
        <Box position="sticky" top="20px">
            <Flex
                bg="sp.background"
                w="100%"
                borderRadius="md"
                borderWidth="1px"
                direction="column"
                height="fit-content">
                <HStack m={4}>
                    <Input
                        background="white"
                        type="search"
                        placeholder="Search for players"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <IconButton
                        variant="mirage"
                        size="sm"
                        isRound={true}
                        onClick={handleAddNewPlayer}
                        aria-label="Add new player"
                        icon={<AddIcon />}
                    />
                </HStack>
                {isPlayerListLoading ? (
                    <Container centerContent mt={4}>
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="md" />
                    </Container>
                ) : null}
                {!isPlayerListLoading && !playersList?.length ? (
                    <Flex borderRadius="md" m={4} justifyContent="center">
                        <Text px="3" py="1" bg="sp.white" borderRadius="md">
                            Squad list is empty
                        </Text>
                    </Flex>
                ) : null}
                <Wrap p={5} spacing={3} overflow="visible">
                    {freePlayers.map((player, i) => (
                        <WrapItem key={i}>
                            <Player
                                player={player}
                                isHomeTeam={isHomeTeam}
                                isSquadPlayer={true}
                                currentActivePlayer={currentActivePlayer}
                                onPlayerClick={handlePlayerClick}
                                index={i}
                            />
                        </WrapItem>
                    ))}
                </Wrap>
            </Flex>
            <Modal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                size={modalSize}
                scrollBehavior="outside"
                returnFocusOnClose={false}>
                <ModalOverlay />
                <AddPlayerModal
                    takenShirtNumbers={takenShirtNumbers}
                    onAddNewPlayer={handleAddNewSquadPlayer}
                    currentActivePlayer={currentEditPlayer}
                    isSquadPlayer={true}
                />
            </Modal>
        </Box>
    )
}
