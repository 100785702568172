import { Router } from '@tanstack/react-router'

import { queryClient } from '@queries/client'

import { appIndex } from '@routes/App'
import { lineupsEditor } from '@routes/LineupsEditor'
import { lineupsCompletedRoute, lineupsToDoRoute } from '@routes/LineupsEditorCompetitionList'
import { matchEventCompletedRoute, matchEventToDoRoute } from '@routes/MatchEventEditorCompetitionList'
import { matchEventEditor } from '@routes/MatchEventsEditor'
import { rootRoute } from '@routes/Root'

const routeTree = rootRoute.addChildren([
    appIndex.addChildren([
        lineupsEditor,
        matchEventEditor,
        lineupsCompletedRoute,
        lineupsToDoRoute,
        matchEventCompletedRoute,
        matchEventToDoRoute,
    ]),
])

export const router = new Router({
    routeTree,
    context: {
        queryClient,
    },
})

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

// https://github.com/linhub15/trumelo/tree/main/bookspace
// https://github.com/Benanna2019/Tanstack-Junk-Drawer/blob/main/src/routes/salesRoutes.tsx
// https://github.com/TanStack/router/blob/main/examples/react/kitchen-sink-react-query/src/main.tsx
