import { useEffect, useMemo } from 'react'
import { Box, Center, Divider, HStack, Spinner } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'
import { useRouter } from '@tanstack/react-router'
import { components, GroupBase, MenuProps, OptionBase, OptionProps, Select } from 'chakra-react-select'
import { groupBy } from 'lodash'

import { useSearchState } from '@hooks/useSearchState'

import { editorPaths } from '@common/config'

import { useCompetitionsListData } from '@queries/useCompetitionsListData'
import { useUser } from '@queries/useUser'

type AppProps = {
    date: string
}

type Option = OptionBase & {
    value?: string
    label?: string
    isLive?: boolean
}

type GroupedOption = GroupBase<Option>

type MatchOptionProps = OptionProps<Option, false>

function MatchOption(props: MatchOptionProps) {
    return (
        <components.Option {...props}>
            <HStack justifyContent="space-between">
                <Box as="span">
                    {props.data.label}
                    {props.data.isLive && (
                        <Box as="span" color="red" fontWeight="bold" ml={1}>
                            [live]
                        </Box>
                    )}
                </Box>
                <Box as="span" fontFamily="monospace">
                    [{props.data.value}]
                </Box>
            </HStack>
        </components.Option>
    )
}

const MenuStyles = (base: CSSObject) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
})

function Menu(props: MenuProps<Option, false, GroupedOption>) {
    return (
        <components.Menu {...props}>
            <Box boxShadow="lg">{props.children}</Box>
        </components.Menu>
    )
}

type FilterOptionOption<OptionType> = Option & {
    data: OptionType
}

function matchSearchFilter(option: FilterOptionOption<Option>, searchText: string) {
    const { label, value, isLive } = option.data

    if (label?.length === 0 && value?.length === 0) {
        return false
    }

    return !!(
        label?.toLowerCase().includes(searchText.toLowerCase()) ||
        value?.toLowerCase().includes(searchText.toLowerCase()) ||
        (searchText.toLowerCase() === 'live' && isLive)
    )
}

function formatGroupLabel(data: GroupedOption) {
    return (
        <Box as="span" fontWeight="bold" fontSize="large">
            <Divider />
            {data.label}
            <Divider />
        </Box>
    )
}

export function MatchSelect({ date }: AppProps) {
    const router = useRouter()

    const { isAuthenticated } = useUser()
    const [searchState, setSearchState] = useSearchState()

    const selectedMatchId = searchState.matchId

    const { dataQualityMatches, isDataQualityMatchesLoading: isLoading } = useCompetitionsListData(date)

    const competitions = Object.entries(groupBy(dataQualityMatches, event => event.competition?.name))

    const selectOptions = competitions.map(competition => {
        const options = competition[1]?.map(match => ({
            label: `${match?.team1?.name} - ${match?.team2?.name}`,
            value: `br:match:${match?.id?.split(':').pop()}`,
            isLive: match?.match_state === 1,
        }))

        const competitionLabel =
            competition[0] === 'undefined' || competition[0]?.length === 0 ? 'Other' : competition[0]

        return { label: competitionLabel, options, value: null }
    })

    const defaultValue = useMemo(() => {
        const selectedOption = selectOptions
            .find(option => option.options.find(match => match.value === selectedMatchId))
            ?.options.find(match => match.value === selectedMatchId)

        return selectedOption
    }, [selectOptions, selectedMatchId])

    useEffect(() => {
        if (!isLoading && selectOptions?.length && selectedMatchId && !defaultValue) {
            setSearchState({ matchId: undefined })
        }
    }, [defaultValue, selectedMatchId, isLoading, selectOptions])

    const handleMatchSelect = id => {
        const { pathname } = router.state.location

        const matchingEditor = Object.keys(editorPaths).find(editor => pathname.includes(editor))

        if (matchingEditor) {
            router.navigate({ to: editorPaths[matchingEditor] })
        }

        setSearchState({ ...searchState, ...{ matchId: id } })
    }

    if (!isAuthenticated) {
        return (
            <Box w="100%" position="relative" zIndex={50}>
                <Center>
                    <Spinner size="xs" />
                </Center>
            </Box>
        )
    }

    return (
        <Box w="100%" position="relative" zIndex={50}>
            <Select
                components={{ Option: MatchOption, Menu }}
                styles={{ menu: MenuStyles }}
                value={defaultValue}
                defaultValue={defaultValue}
                isSearchable
                filterOption={matchSearchFilter}
                isLoading={isLoading}
                size="md"
                options={selectOptions}
                onChange={e => e?.value && handleMatchSelect(e?.value)}
                placeholder={'Select match...'}
                formatGroupLabel={formatGroupLabel}
            />
        </Box>
    )
}
