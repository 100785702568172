import {
    Box,
    Button,
    Center,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
} from '@chakra-ui/react'

import { MicrosoftIcon } from '@components/Icons/MicrosoftIcon'
import { SuperbetLogo } from '@components/Icons/SuperbetLogo'

import { useUser } from '@queries/useUser'

export function Login() {
    const { login, isLoading = false } = useUser()

    console.log('USER IS LOADING', isLoading)

    return (
        <Box height="100vh">
            <Modal
                isOpen={true}
                onClose={() => null}
                size="2xl"
                // `trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly.
                blockScrollOnMount={true}
                trapFocus={true}
                isCentered={true}
            >
                <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
                <ModalContent borderRadius="2xl" mx="4">
                    <ModalHeader>
                        <Center pt={8}>
                            <SuperbetLogo w="160px" h="36px" />
                        </Center>
                    </ModalHeader>
                    <ModalBody>
                        <Box p={8}>
                            <Stack spacing="8">
                                <Stack spacing="6">
                                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                                        <Heading size="lg" fontWeight="600" color="blue.800">
                                            Log in to Stats Tools
                                        </Heading>
                                        <Text color="grey">Don't have an account? What are you doing here?!</Text>
                                    </Stack>
                                </Stack>
                                <Box>
                                    <Stack spacing="6">
                                        <Button
                                            isLoading={isLoading}
                                            colorScheme="blue"
                                            variant="outline"
                                            onClick={() => login('SuperbetMicrosoft365Provider')}>
                                            <MicrosoftIcon boxSize="5" />
                                            <Text ml="3">Sign in with Microsoft</Text>
                                        </Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}
