import { useEffect, useMemo } from 'react'
import { Box, BoxProps, Button, Center, Container, Divider, Hide, HStack, Show } from '@chakra-ui/react'
import { Link, useRouter } from '@tanstack/react-router'

import { EventPicker } from '@components/EventPicker/EventPicker'
import { SuperbetLogo } from '@components/Icons/SuperbetLogo'
import { SuperbetLogoMini } from '@components/Icons/SuperbetLogoMini'
import { User } from '@components/User'

import { useSearchState } from '@hooks/useSearchState'

import { editorPaths, editorsList, TEditorsList } from '@common/config'

import { useUser } from '@queries/useUser'

import { GlobalSearchParams } from '@routes/Root'

export function Navbar(props: BoxProps) {
    const { userData } = useUser()

    const [search] = useSearchState()

    // Seriously, I regret picking TanStack Router.
    // It's so bad with these common real life scenarios like Search Params as State...
    const searchParams = useMemo<GlobalSearchParams>(
        () => ({
            date: search?.date,
            filter: search?.filter,
            matchId: search?.matchId,
        }),
        [search],
    )

    const homeSearchParams = useMemo<GlobalSearchParams>(
        () => ({
            date: search?.date,
            filter: search?.filter,
        }),
        [search],
    )

    const router = useRouter()

    const isLineupsEditorAllowed = userData.cognitoGroups?.includes('lineups_editor_admin')

    const isMatchEventEditorAllowed = userData.cognitoGroups?.includes('match_events_editor_admin')

    const getRoute = (editor: TEditorsList) => (search?.matchId ? editorPaths[editor] : `${editorPaths[editor]}/todo`)

    const getHomeRoute = () => {
        const { pathname } = router.state.location
        switch (true) {
            case pathname.includes(editorPaths[editorsList.LINEUP_EDITOR]) && isLineupsEditorAllowed:
                return `${editorPaths[editorsList.LINEUP_EDITOR]}/todo`

            case pathname.includes(editorPaths[editorsList.MATCH_EVENT_EDITOR]) && isMatchEventEditorAllowed:
                return `${editorPaths[editorsList.MATCH_EVENT_EDITOR]}/todo`

            default:
                return (
                    (isLineupsEditorAllowed && `${editorPaths[editorsList.LINEUP_EDITOR]}/todo`) ||
                    (isMatchEventEditorAllowed && `${editorPaths[editorsList.MATCH_EVENT_EDITOR]}/todo`) ||
                    '/'
                )
        }
    }

    return (
        <Box as="nav" role="navigation" {...props} p={{ base: 4, xl: 8 }}>
            <Container
                flex="1"
                maxW="container.xxl"
                px={{ base: 2, sm: 4, xl: 8 }}
                py={{ base: 2, sm: 4, xl: 0 }}
                bg="white"
                borderRadius="16"
                boxShadow="lg"
            >
                <HStack
                    gap={{ base: 4, xl: 8 }}
                    minH="20"
                    justifyContent="space-between"
                    wrap={{ base: 'wrap', xl: 'nowrap' }}>
                    <HStack gap={{ base: 2, md: 4, xl: 8 }} justifyContent="space-between">
                        <Link to={getHomeRoute()} activeOptions={{ includeSearch: false }} search={homeSearchParams}>
                            <Show above="md">
                                <SuperbetLogo w="160px" h="36px" />
                            </Show>
                            <Hide below="34em">
                                <Hide above="md">
                                    <SuperbetLogoMini w="36px" h="36px" />
                                </Hide>
                            </Hide>
                        </Link>
                        <Hide below="34em">
                            <Center height="10">
                                <Divider orientation="vertical" />
                            </Center>
                        </Hide>
                        <Link
                            to={getRoute(editorsList.LINEUP_EDITOR)}
                            activeOptions={{ includeSearch: false }}
                            search={searchParams}
                        >
                            {({ isActive }) => (
                                <Button
                                    isDisabled={!isLineupsEditorAllowed}
                                    variant={
                                        isActive
                                            ? 'mirage'
                                            : 'outline'
                                    }>
                                    Lineups Editor
                                </Button>
                            )}

                        </Link>
                        <Link
                            to={getRoute(editorsList.MATCH_EVENT_EDITOR)}
                            activeOptions={{ includeSearch: false }}
                            search={searchParams}
                        >
                            {({ isActive }) => (
                                <Button
                                    isDisabled={!isMatchEventEditorAllowed}
                                    variant={
                                        isActive
                                            ? 'mirage'
                                            : 'outline'
                                    }>
                                    Match Events Editor
                                </Button>
                            )}

                        </Link>
                    </HStack>
                    <Show above="xl">
                        <Center height="10">
                            <Divider orientation="vertical" />
                        </Center>
                    </Show>
                    <Box order={{ base: 2, xl: 1 }} w="100%">
                        <EventPicker />
                    </Box>
                    <HStack
                        flex={1}
                        gap={{ base: 2, md: 4, xl: 8 }}
                        order={{ base: 1, xl: 2 }}
                        justifyContent={{ base: 'end', xl: 'normal' }}>
                        <Center height="10">
                            <Divider orientation="vertical" />
                        </Center>
                        <User />
                    </HStack>
                </HStack>
            </Container>
        </Box>
    )
}
