import { useSyncExternalStore } from 'react'

function useVisibilityChangeSubscribe(callback: (this: Document, ev: Event) => any) {
    document.addEventListener('visibilitychange', callback)

    return () => {
        document.removeEventListener('visibilitychange', callback)
    }
}

function getVisibilityChangeSnapshot() {
    return document.visibilityState
}

export function useIsWindowFocused() {
    const visibilityState = useSyncExternalStore(useVisibilityChangeSubscribe, getVisibilityChangeSnapshot, () => null)

    return visibilityState === 'visible'
}
