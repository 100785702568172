/* eslint-disable @typescript-eslint/naming-convention */
import { WarningIcon } from '@chakra-ui/icons'
import { Checkbox, Flex, Tooltip } from '@chakra-ui/react'

import { MetaData, StateEnum } from '@common/types'

import { Comment } from './Comment'
import { useMetaData } from './hooks/useMetaData'
import { ModifiedInSelect } from './ModifiedInSelect'
import { ReasonSelect } from './ReasonSelect'

type MatchMetaDataProps = {
    matchId?: string
    metaData: MetaData | null
    hideTitles?: boolean
    isLineupNotCompleted?: boolean
}

export function LineupsMetaData({ matchId, metaData, isLineupNotCompleted, hideTitles }: MatchMetaDataProps) {
    const {
        handleCommentChange,
        handleModifiedInChange,
        handleReasonChange,
        handleStateChange,
        reason,
        modifiedIn,
        comment,
        state,
    } = useMetaData(matchId, metaData)

    return (
        <>
            <ReasonSelect reason={reason} handleReasonChange={handleReasonChange} />
            <ModifiedInSelect modifiedIn={modifiedIn} handleModifiedInChange={handleModifiedInChange} />
            <Flex gap={4} alignItems="center">
                <Comment comment={comment} handleSendComment={handleCommentChange} />
                <Checkbox
                    isChecked={state === StateEnum.COMPLETED}
                    size="lg"
                    colorScheme="green"
                    onChange={handleStateChange}
                >
                    {hideTitles ? null : 'Completed'}
                </Checkbox>

                {isLineupNotCompleted && (
                    <Tooltip label="Lineup not completed" fontSize="md" hasArrow placement="top">
                        <WarningIcon color="red.500" />
                    </Tooltip>
                )}
            </Flex>
        </>
    )
}
