const baseStyle = {
    fontWeight: 'bold',
    _hover: {
        _disabled: {
            bg: null,
        },
    },
}

const variants = {
    green: {
        backgroundColor: 'sp.green',
        color: 'sp.white',
        _hover: {
            backgroundColor: '#167D58',
        },
        _active: {
            backgroundColor: '#167351',
        },
    },
    red: {
        backgroundColor: 'sp.red',
        color: 'sp.white',
        _hover: {
            backgroundColor: '#DC2C40',
        },
        _active: {
            backgroundColor: '#C7273A',
        },
    },
    mirage: {
        backgroundColor: 'sp.mirage',
        color: 'sp.white',
        _hover: {
            backgroundColor: '#202044',
        },
        _active: {
            backgroundColor: '#292956',
        },
    },
    navigation: {
        fontWeight: 'normal',
        backgroundColor: 'sp.transparent',
        color: 'sp.white',
        justifyContent: 'start',
        _hover: {
            backgroundColor: 'whiteAlpha.300',
        },
        _selected: {
            backgroundColor: 'whiteAlpha.300',
            fontWeight: 'bold',
        },
    },
    over: {
        opacity: 0.5,
        color: 'sp.white',
        backgroundColor: '#202044',
        transition: 'opacity 0.5s, backgroundColor 0.5s',
    },
}

const sizes = {
    lg: {
        fontSize: 'md',
    },
}

export const Button = { baseStyle, variants, sizes }
