import { extendTheme, ThemeConfig } from '@chakra-ui/react'

import { components } from './components'
import { foundations } from './foundation'

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

export default extendTheme({
    ...foundations,
    components,
    config,
    styles: {
        global: () => ({
            body: {
                bg: '#f2f4f6;',
            },
        }),
    },
})
