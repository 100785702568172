import { Box, BoxProps, Container } from '@chakra-ui/react'

export function Footer(props: BoxProps) {
    return (
        <Box as="footer" role="contentinfo" {...props}>
            <Container flex="1" maxW="container.xxl">
                <Box minH="20" />
            </Container>
        </Box>
    )
}
