import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { baseStatsApiURL } from '@common/config'

async function fetchTeamOverview(id?: string) {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    const response = await fetch(`${baseStatsApiURL}/soccer/teams/teamoverview/comsuperbetsport/en-GB?team_id=${id}`)

    if (response.status === 200) {
        return response.json()
    }

    // @TODO: Will Čačko ever add some error responses to the API?
    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

export function useTeamOverview(id?: string | number, options: Pick<UseQueryOptions, 'enabled'> = {}) {
    return useQuery({
        enabled: !!id,
        ...options,
        queryKey: ['teams', 'overview', id],
        queryFn: () => fetchTeamOverview(String(id)),
    })
}
