import { useMemo } from 'react'

import { MatchDetails } from '@common/types'

import { useCompetitionsListData } from '@queries/useCompetitionsListData'

import { useSearchState } from './useSearchState'

export function useSelectedMatchDetails(): MatchDetails {
    const [{ matchId: selectedMatchId, date = new Date() }] = useSearchState()

    const { dataQualityMatches } = useCompetitionsListData(date)

    const selectedMatchDetails = useMemo(() => {
        const selectedMatch = dataQualityMatches?.find(
            match => `br:match:${match.id.split(':').pop()}` === selectedMatchId,
        )

        return {
            ...selectedMatch,
            id: `br:match:${selectedMatch?.id?.split(':').pop()}`,
        }
    }, [selectedMatchId, dataQualityMatches])

    return selectedMatchDetails
}
