import { Box, BoxProps } from '@chakra-ui/react'

import * as icons from '@components/Icons'

interface Props {
    type: keyof typeof icons
}

export function Icon({ type, ...rest }: Props & BoxProps) {
    const ResolvedIconComponent = icons[type]

    return <Box as={ResolvedIconComponent} {...rest} />
}
