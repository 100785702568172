/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react'
import { VStack, Wrap, WrapItem } from '@chakra-ui/react'

import { NewSubs } from '@common/types'

import { Player } from '../Player'
import { Droppable } from './Droppable'
import { EmptyPlayer } from './EmptyPlayer'

interface SubstitutionsEditorProps {
    substitutions: NewSubs
    isHomeTeam: boolean
    currentActivePlayer: any
    handleEditPlayer: (player) => void
    handleAddPlayer: (position) => void
    handleSubsChanges: (subs: NewSubs) => void
}

export function SubstitutionsEditor({
    substitutions,
    isHomeTeam,
    currentActivePlayer,
    handleEditPlayer,
    handleAddPlayer,
    handleSubsChanges,
}: SubstitutionsEditorProps) {
    const [activeDroppable, setActiveDroppable] = useState<any | null>(null)

    const onRemovePlayer = (index: number) => {
        const newLineup = [...substitutions]
        newLineup[index] = null

        handleSubsChanges(newLineup)
    }

    const getPlayerZIndex = (player, droppableId) => {
        switch (true) {
            case currentActivePlayer?.name === player.name:
                return 12

            case activeDroppable?.id === droppableId:
                return 0

            default:
                return 10
        }
    }

    const handlePlayerClick = player => {
        handleEditPlayer(player)
    }

    const handleEmptyPlayerClick = position => {
        handleAddPlayer(position)
    }

    return (
        <Wrap bg="sp.background" borderRadius="16" borderWidth="1px" p={5} spacing={3} overflow="visible" minW="176px">
            {substitutions.map((player, i) => (
                <WrapItem key={i}>
                    <Droppable
                        id={`droppable-subs-${i}`}
                        data={{ index: i, isSubstitution: true }}
                        key={`empty-subs-player-${i}`}
                        setActiveDroppable={setActiveDroppable}
                    >
                        {player?.name ? (
                            <VStack
                                zIndex={getPlayerZIndex(player, `droppable-subs-${i}`)}
                                spacing={0.5}
                                alignItems="center"
                                w="3.8rem"
                                h="4.5rem"
                                position="relative"
                                key={`subs-player-${i}`}>
                                <Player
                                    activeDroppable={activeDroppable}
                                    droppableId={`droppable-subs-${i}`}
                                    player={player}
                                    variant="dark"
                                    isHomeTeam={isHomeTeam}
                                    currentActivePlayer={currentActivePlayer}
                                    onDeletePlayerClick={onRemovePlayer}
                                    index={i}
                                    isSubstitution={true}
                                    onPlayerClick={handlePlayerClick}
                                />
                            </VStack>
                        ) : (
                            <EmptyPlayer
                                variant="dark"
                                activeDroppable={activeDroppable}
                                id={`droppable-subs-${i}`}
                                onEmptyPlayerClick={handleEmptyPlayerClick}
                                index={i}
                                isSubstitution={true}
                            />
                        )}
                    </Droppable>
                </WrapItem>
            ))}
        </Wrap>
    )
}
