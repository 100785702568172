import { Route } from '@tanstack/react-router'

import { CompetitionLists } from '@components/CompetitionLists/CompetitionLists'

import { editorsList } from '@common/types'

import { appIndex } from '@routes/App'

export function LineupsEditorCompetitionList() {
    return <CompetitionLists editor={editorsList.LINEUP_EDITOR} />
}

export const lineupsToDoRoute = new Route({
    getParentRoute: () => appIndex,
    path: `/editors/${editorsList.LINEUP_EDITOR}/todo`,
    component: LineupsEditorCompetitionList,
})

export const lineupsCompletedRoute = new Route({
    getParentRoute: () => appIndex,
    path: `/editors/${editorsList.LINEUP_EDITOR}/completed`,
    component: LineupsEditorCompetitionList,
})
