import { Avatar, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react'

import { useUser } from '@queries/useUser'

export function User() {
    const { userData, logout } = useUser()

    return (
        <Menu>
            <MenuButton>
                <Avatar name={userData?.email} />
            </MenuButton>
            <MenuList>
                <MenuGroup title="Profile">
                    <MenuItem onClick={() => logout()}>Log out</MenuItem>
                </MenuGroup>
            </MenuList>
        </Menu>
    )
}
