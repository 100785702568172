import { useCallback, useMemo } from 'react'
import { AnyRoute, FullSearchSchema, useRouter, useSearch } from '@tanstack/react-router'

type SearchParams = FullSearchSchema<AnyRoute>

type SetSearchParams = (changes: Partial<SearchParams>) => Promise<void>

/**
 * Like useState, but for search params.
 */
export function useSearchState(): [SearchParams, SetSearchParams] {
    const router = useRouter()

    const rawSearch = useSearch({ strict: false })

    const setSearchState = useCallback(
        (changes: Partial<SearchParams>) =>
            router.navigate({
                search: (prev => ({ ...prev, ...changes })) as any,
            }),
        [router],
    )

    const searchState = useMemo(() => rawSearch, [rawSearch])

    return [searchState, setSearchState]
}
