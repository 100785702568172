import { useQuery } from '@tanstack/react-query'

import { baseStatsApiURL_NO_CACHE } from '@common/config'

import { useUser } from '../queries/useUser'
import { useSearchState } from './useSearchState'
import { toast } from '@/utils/toast'

async function fetchData(id?: number | string, accessToken?: string) {
    if (!id) {
        return undefined
    }

    if (!accessToken) {
        toast({
            position: 'top',
            description: 'Missing user access token!',
            status: 'error',
            duration: 9000,
            isClosable: true,
        })

        throw new Error('No access token provided')
    }

    const response = await fetch(`${baseStatsApiURL_NO_CACHE}/default/data-provider/api/formation?match_id=${id}`, {
        headers: {
            Authentication: accessToken,
        },
    })

    const json = await response.json()
    if (response.status !== 200) {
        toast({
            position: 'top',
            description: 'Error fetching lineup!',
            status: 'error',
            duration: 9000,
            isClosable: true,
        })
    }

    return json
}

export function useMatchLineup(matchId: number | string | null) {
    const { userData } = useUser()
    const [{ matchId: selectedMatchId }] = useSearchState()

    const {
        data: matchLineup,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['useMatchLineup', matchId, userData.accessToken, selectedMatchId],
        queryFn: () => fetchData(selectedMatchId || matchId, userData.accessToken),
        refetchOnWindowFocus: false,
    })

    return { matchLineup: matchLineup?.lineups, isLoading, isError }
}
