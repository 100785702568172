import { createIcon } from '@chakra-ui/react'

export const SuperbetLogoMini = createIcon({
    displayName: 'SuperbetLogoMini',
    viewBox: '0 0 48 48',
    path: (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none">
            <path
                fill="#EA060A"
                fillRule="evenodd"
                d="M23.619 8.111c3.432 0 4.783 1.612 5.355 4.108l10.035-.052C38.852 4.42 33.654 0 23.775 0c-10.45 0-14.92 5.148-14.92 11.439 0 6.29 3.95 9.67 12.581 11.022l4.264.676c2.963.468 4.055 1.352 4.055 3.12 0 2.027-1.612 3.223-5.407 3.223-4.94 0-6.291-2.08-6.032-4.783H8.178c-.572 8.631 5.407 12.894 15.961 12.894s15.754-5.25 15.754-12.01c0-6.031-3.068-9.203-11.699-10.555l-4.367-.676c-3.432-.52-4.836-1.247-4.836-3.223 0-1.716 1.196-3.016 4.628-3.016M7.213 43.57A33.9 33.9 0 0 0 24.004 48h-.002a33.9 33.9 0 0 0 16.79-4.43l2.19-1.249c.498-.284.671-.918.387-1.417l-2.495-4.376a1.04 1.04 0 0 0-1.418-.388l-1.673.954a27.81 27.81 0 0 1-27.56 0l-1.674-.954a1.04 1.04 0 0 0-1.417.388l-2.495 4.376a1.04 1.04 0 0 0 .388 1.417z"
                clipRule="evenodd"
            />
        </svg>
    ),
})
