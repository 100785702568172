import { Route } from '@tanstack/react-router'

import { Shell } from '@layouts/Shell'

import { rootRoute } from './Root'

export const appIndex = new Route({
    getParentRoute: () => rootRoute,
    path: '/',
    component: Shell,
})
