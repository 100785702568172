import { Route } from '@tanstack/react-router'

import { CompetitionLists } from '@components/CompetitionLists/CompetitionLists'

import { editorsList } from '@common/types'

import { appIndex } from '@routes/App'

export function MatchEventsEditorCompetitionList() {
    return <CompetitionLists editor={editorsList.MATCH_EVENT_EDITOR} />
}

export const matchEventToDoRoute = new Route({
    getParentRoute: () => appIndex,
    path: `/editors/${editorsList.MATCH_EVENT_EDITOR}/todo`,
    component: MatchEventsEditorCompetitionList,
})

export const matchEventCompletedRoute = new Route({
    getParentRoute: () => appIndex,
    path: `/editors/${editorsList.MATCH_EVENT_EDITOR}/completed`,
    component: MatchEventsEditorCompetitionList,
})
