import { AspectRatio, Flex, Text } from '@chakra-ui/react'

interface NoLineupProps {
    message: string
}

export function NoLineup({ message }: NoLineupProps) {
    return (
        <AspectRatio
            w="full"
            backgroundImage="url('/images/lineup-court.svg')"
            backgroundPosition="center"
            bgRepeat="no-repeat"
            ratio={365 / 480}
        >
            <Flex bg="rgba(0,0,0,0.3)" w="full" h="full" borderRadius="md">
                <Text px="3" py="1" bg="sp.white" borderRadius="md">
                    {message}
                </Text>
            </Flex>
        </AspectRatio>
    )
}
