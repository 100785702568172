import { useCallback, useState } from 'react'
import { LockIcon, UnlockIcon, WarningIcon } from '@chakra-ui/icons'
import { Box, Button, Checkbox, Flex, Modal, ModalContent, ModalOverlay, Tooltip } from '@chakra-ui/react'

import { MetaData, StateEnum } from '@common/types'

import { Comment } from './Comment'
import { useMetaData } from './hooks/useMetaData'
import { ModifiedInSelect } from './ModifiedInSelect'
import { ReasonSelect } from './ReasonSelect'

type MatchMetaDataProps = {
    matchId?: string
    metaData: MetaData | null
    isLineupNotCompleted?: boolean
    hideTitles?: boolean
}

export function MatchEventsMetaData({ matchId, metaData, isLineupNotCompleted, hideTitles }: MatchMetaDataProps) {
    const {
        handleCommentChange,
        handleLockTimelineChange,
        handleModifiedInChange,
        handleReasonChange,
        handleStateChange,
        isTimelineLocked,
        reason,
        modifiedIn,
        comment,
        state,
    } = useMetaData(matchId, metaData)

    const [showUnlockConfirmModal, setShowUnlockConfirmModal] = useState(false)

    const handleConfirmUnlockClick = useCallback(() => {
        setShowUnlockConfirmModal(false)

        handleLockTimelineChange(false)
    }, [handleLockTimelineChange])

    const lockedTitle = isTimelineLocked ? 'Timeline locked' : 'Timeline unlocked'

    return (
        <>
            <ReasonSelect reason={reason} handleReasonChange={handleReasonChange} />
            <ModifiedInSelect modifiedIn={modifiedIn} handleModifiedInChange={handleModifiedInChange} />
            <Flex gap={4} alignItems="center">
                <Comment comment={comment} handleSendComment={handleCommentChange} />
                <Checkbox
                    isChecked={state === StateEnum.COMPLETED}
                    size="lg"
                    colorScheme="green"
                    onChange={handleStateChange}
                >
                    {hideTitles ? null : 'Completed'}
                </Checkbox>
                <Button
                    gap={4}
                    onClick={() => {
                        if (isTimelineLocked) {
                            setShowUnlockConfirmModal(true)
                        } else {
                            handleLockTimelineChange(true)
                        }
                    }}>
                    {isTimelineLocked ? <LockIcon /> : <UnlockIcon />}
                    {hideTitles ? null : lockedTitle}
                </Button>

                {isLineupNotCompleted && (
                    <Tooltip label="Lineup not completed" fontSize="md" hasArrow placement="top">
                        <WarningIcon color="red.500" />
                    </Tooltip>
                )}
            </Flex>
            <Modal isOpen={showUnlockConfirmModal} onClose={() => setShowUnlockConfirmModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <Box p={4}>
                        <p>Are you sure you want to unlock the timeline?</p>
                        <p>This will ANNIHILATE all the events during the lock period.</p>
                        <Flex alignItems="center" justifyContent="space-around" p={4}>
                            <Button onClick={() => setShowUnlockConfirmModal(false)}>Cancel</Button>
                            <Button onClick={handleConfirmUnlockClick}>Unlock</Button>
                        </Flex>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}
