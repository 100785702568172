import { Box } from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

type AppProps = {
    date: Date
    setDate: (newDate: Date) => void
}

const propsConfig = {
    inputProps: { size: 'md', placeholder: 'Choose date' },
    popoverCompProps: {
        popoverContentProps: { boxShadow: 'lg' },
    },
    calendarPanelProps: {
        wrapperProps: {
            border: 'none',
        },
        contentProps: {
            border: 'none',
        },
    },
}

export function DateSelect({ date, setDate }: AppProps) {
    return (
        <Box maxW="130" zIndex="100">
            <SingleDatepicker name="date-input" date={date} onDateChange={setDate} propsConfigs={propsConfig} />
        </Box>
    )
}
