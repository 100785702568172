/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { OptionBase } from 'chakra-react-select'

import { EnrichedMatchEditorApiEvent, MatchEventEditorEventType, MatchTime } from '@common/types'

export type Option = OptionBase & {
    value?: string
    label?: string
}

export type CreatableOption = Option & {
    __isNew__?: boolean
}

export function makePrettierLabel(label: string) {
    const words = label.split('_')
    const prettierLabel = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

    return prettierLabel
}

export function getMatchEditorEventTypeLabel(key: string) {
    const eventType = MatchEventEditorEventType[key as keyof typeof MatchEventEditorEventType]
    const eventTypeLabel = Object.keys(MatchEventEditorEventType).find(
        k => MatchEventEditorEventType[k as keyof typeof MatchEventEditorEventType] === eventType,
    )

    // Exception for VAR mapping, as we're trying to keep this API sourced as every other event
    if (eventTypeLabel === 'var_over') {
        return 'VAR'
    }

    if (eventTypeLabel) {
        return makePrettierLabel(eventTypeLabel)
    }

    return '-'
}

export type FilterOptionOption<OptionType> = Option & {
    data: OptionType
}

export function playerSearchFilter(option: FilterOptionOption<Option>, searchText: string) {
    const { label, value } = option.data

    if (label?.length === 0 && value?.length === 0) {
        return false
    }

    return !!(
        label?.toLowerCase().includes(searchText.toLowerCase()) ||
        value?.toLowerCase().includes(searchText.toLowerCase())
    )
}

export function formatMatchTime(matchTime: MatchTime) {
    const { minutes, seconds, additionalMinutes } = matchTime

    const formattedMinutes = Number(minutes).toString().padStart(2, '0')
    let formattedSeconds = Number(seconds).toString().padStart(2, '0')
    let formattedAdditionalMinutes = ''

    if (minutes === 45 || minutes === 90 || minutes === 120) {
        formattedSeconds = '00'

        if (additionalMinutes?.length && Number(additionalMinutes) > 0) {
            formattedAdditionalMinutes = ` +${Number(additionalMinutes).toString().padStart(2, '0')}:${Number(seconds)
                .toString()
                .padStart(2, '0')}`
        }
    }

    const result = `${formattedMinutes}:${formattedSeconds}${formattedAdditionalMinutes}`

    return result
}

export function getPlayerIdFromOption(option?: Option | CreatableOption) {
    if (!option) {
        return null
    }

    if ((option as CreatableOption)?.__isNew__ || option.value?.includes('is:fake:player:')) {
        return null
    }

    return option.value?.length ? option.value : null
}

export function getPlayerNameFromOption(option?: Option | CreatableOption) {
    if (!option) {
        return null
    }

    // If we're reusing fake player, we're sending both name and ID, thus parsing by 'fake',
    // as returned fake IDs look like 'fake:player:-576460752303411742
    if ((option as CreatableOption)?.__isNew__ || option.value?.includes('fake')) {
        return option.label?.length ? option.label : null
    }

    return null
}

export function getPlayerOptionFromId(id: string, options: Option[]) {
    const playerOption = options.find(option => option.value === id)

    if (!id || !playerOption) {
        return {
            value: '',
            label: 'Unknown (no id)',
        }
    }

    return playerOption
}

export function extractPlayersFromEvents(teamId: string, events: EnrichedMatchEditorApiEvent[]) {
    const playersMap = events.reduce((accumulator, event) => {
        if (event.team_id !== teamId) {
            return accumulator
        }

        if (event.player1_id && event.player1_id.includes('fake:player')) {
            const player1 = {
                value: event.player1_id,
                label: event?.player1_name ?? event.player1_id,
            }

            const existingPlayer = accumulator.get(player1.value)
            if (!existingPlayer || (existingPlayer && player1.label !== player1.value)) {
                accumulator.set(player1.value, player1)
            }
        }

        if (event.player2_id && event.player2_id.includes('fake:player')) {
            const player2 = {
                value: event.player2_id,
                label: event?.player2_name ?? event.player2_id,
            }

            const existingPlayer = accumulator.get(player2.value)
            if (!existingPlayer || (existingPlayer && player2.label !== player2.value)) {
                accumulator.set(player2.value, player2)
            }
        }

        return accumulator
    }, new Map<string, Option>())

    return Array.from(playersMap.values())
}

export function createOptions(obj: any, labelMaker: (key: string) => string) {
    return Object.keys(obj).map(key => ({
        value: String(obj[key as keyof typeof obj]),
        label: labelMaker(key),
    }))
}

export function findOption(value: string, options: Option[]) {
    return options.find(option => String(option.value) === value)
}
