import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import { theme } from '@theme/index'

import { queryClient } from '@queries/client'

// import { router } from '@routes/index'
import { router } from './router'

export function App() {
    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} context={{ queryClient }} />
            </QueryClientProvider>
        </ChakraProvider>
    )
}
