const variants = {
    filled: {
        field: {
            bg: 'sp.purpleLight',
            _hover: {
                bg: null,
            },
            _focus: {
                bg: 'sp.purpleLight',
            },
        },
    },
    filledGrey: {
        field: {
            bg: 'sp.grey',
            _hover: {
                bg: null,
            },
            _focus: {
                bg: 'sp.grey',
            },
        },
    },
}

const sizes = {
    lg: {
        field: {
            fontSize: 'md',
        },
    },
}

export const Input = { variants, sizes }
