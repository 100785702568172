import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { baseStatsApiURL } from '@common/config'

type SimplePlayer = {
    id: string
    name: string
}

const playerKeys = ['midfielders', 'goalkeepers', 'defenders', 'attackers']

function getPlayerData(players?: { player: SimplePlayer }[]) {
    return (
        players?.map(player => ({
            id: player.player?.id,
            name: player.player?.name,
        })) || []
    )
}

function selectPlayersData(teamSquad: { [key: string]: { player: SimplePlayer }[] }) {
    return playerKeys.flatMap(key => getPlayerData(teamSquad[key]))
}

async function fetchTeamSquad(id?: string) {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    const response = await fetch(`${baseStatsApiURL}/soccer/teams/squad/comsuperbetsport/en-GB?team_id=${id}`)

    if (response.status === 200) {
        return response.json()
    }

    // @TODO: Will Čačko ever add some error responses to the API?
    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

export function useTeamSquad(id?: string | number, options: Pick<UseQueryOptions, 'enabled'> = {}) {
    return useQuery({
        enabled: !!id,
        ...options,
        queryKey: ['teams', 'squad', id],
        queryFn: () => fetchTeamSquad(String(id)),
        select: selectPlayersData,
    })
}
