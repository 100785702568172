/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Center,
    Heading,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react'

import { CollapsableToDrawer } from '@components/CollapsableToDrawer'
import { LineupsMetaData } from '@components/MatchMetaData/LineupsMetaData'

import { useCreateNewLineup } from '@hooks/useCreateNewLineup'
import { useMatchLineup } from '@hooks/useMatchLineup'
import { usePreviousLineup } from '@hooks/usePreviousLineup'
import { useSelectedMatchDetails } from '@hooks/useSelectedMatchDetails'

import { FixtureLineup, MetaData, StateEnum } from '@common/types'
import { deleteLineupFromCache, getLineupFromCache } from '@common/utils'

import { useLineupDataContext } from '@contexts/LineupDataContext'

import { useMissingPlayers } from '@queries/useMissingPlayers'

import { LineupEditContainer } from '../LineupAndSubsPreview/LineupEditModal/LineupEditContainer'

const defaultFormation = '4-4-2'

const transformLineupData = (lineup: any[]) =>
    lineup?.length
        ? lineup.map((player, index) => ({
              id: player.player_id,
              name: player.name,
              shirt_number: player.shirt_number,
              order: player.formation_place,
              starter: !player.substitute,
          }))
        : Array.from({ length: 11 }, () => ({ starter: true }))

const checkIsLineupWithoutFormation = (lineup: any[], formation: string | null) => Boolean(lineup?.length && !formation)

const getFormation = (lineup: any[], formation: string | null) =>
    checkIsLineupWithoutFormation(lineup, formation) ? defaultFormation : formation

const checkAreAllPlayersIn = lineup => lineup.flat().filter(p => p !== null && p.name && p.starter).length === 11

export function LineupAndSubsPreviewTabs() {
    const selectedMatchDetails = useSelectedMatchDetails()

    const { matchLineup, isLoading } = useMatchLineup(selectedMatchDetails.id)
    const { missingPlayers } = useMissingPlayers(selectedMatchDetails.id)

    const { lineupData, setLineupData } = useLineupDataContext()
    const { postData, isLoading: isPostDataLoading, isError: isPostDataError } = useCreateNewLineup()
    const [isTeam1WithoutFormation, setIsTeam1WithoutFormation] = useState<boolean>(false)
    const [isTeam2WithoutFormation, setIsTeam2WithoutFormation] = useState<boolean>(false)
    const [isTeam1PreviousLineup, setIsTeam1PreviousLineup] = useState<boolean>(false)
    const [isTeam2PreviousLineup, setIsTeam2PreviousLineup] = useState<boolean>(false)
    const [initialLineupData, setInitialLineupData] = useState<FixtureLineup>()
    const [metaData, setMetaData] = useState<MetaData | null>(selectedMatchDetails.lineups_editor_meta)

    const handleSaveLineup = () => {
        postData(lineupData)

        if (lineupData.match_id && !isPostDataError) {
            deleteLineupFromCache(lineupData.match_id)
        }

        setMetaData({ ...metaData, ...{ state: StateEnum.IN_PROGRESS } })
    }

    const { previousLineup } = usePreviousLineup(selectedMatchDetails?.team1?.id, selectedMatchDetails?.team2?.id)

    useEffect(() => {
        let team1Lineup = matchLineup?.team1_lineup

        let team2Lineup = matchLineup?.team2_lineup

        const cachedLineup = getLineupFromCache(selectedMatchDetails?.id)

        setIsTeam1PreviousLineup(false)

        setIsTeam2PreviousLineup(false)

        if (!matchLineup?.team1_lineup.length && previousLineup?.team1?.lineup?.length) {
            team1Lineup = previousLineup?.team1.lineup

            setIsTeam1PreviousLineup(true)
        }

        if (!matchLineup?.team2_lineup.length && previousLineup?.team1?.lineup?.length) {
            team2Lineup = previousLineup?.team2.lineup

            setIsTeam2PreviousLineup(true)
        }

        const team1Formation = cachedLineup
            ? cachedLineup.team1_formation
            : matchLineup?.team1_formation ?? previousLineup?.team1?.formation

        const team2Formation = cachedLineup
            ? cachedLineup.team2_formation
            : matchLineup?.team2_formation ?? previousLineup?.team2?.formation

        const team1TransformedLineup = cachedLineup ? cachedLineup.team1_lineup : transformLineupData(team1Lineup)

        const team2TransformedLineup = cachedLineup ? cachedLineup.team2_lineup : transformLineupData(team2Lineup)

        setLineupData(previousLineupData => ({
            ...previousLineupData,
            match_id: selectedMatchDetails?.id,
            match_status: selectedMatchDetails?.match_status ?? null,
            team1_id: selectedMatchDetails?.team1?.id,
            team2_id: selectedMatchDetails?.team2?.id,
            team1_formation: getFormation(team1TransformedLineup, team1Formation),
            team1_lineup: team1TransformedLineup,
            team2_formation: getFormation(team2TransformedLineup, team2Formation),
            team2_lineup: team2TransformedLineup,
        }))

        setInitialLineupData(previousLineupData => ({
            ...previousLineupData,
            match_id: selectedMatchDetails?.id,
            match_status: selectedMatchDetails?.match_status ?? null,
            team1_id: selectedMatchDetails?.team1?.id,
            team2_id: selectedMatchDetails?.team2?.id,
            team1_formation: getFormation(team1Lineup, team1Formation),
            team1_lineup: transformLineupData(team1Lineup),
            team2_formation: getFormation(team2Lineup, team2Formation),
            team2_lineup: transformLineupData(team2Lineup),
        }))
    }, [selectedMatchDetails.id, matchLineup, previousLineup])

    useEffect(() => {
        setIsTeam1WithoutFormation(
            checkIsLineupWithoutFormation(matchLineup?.team1_lineup, matchLineup?.team1_formation),
        )

        setIsTeam2WithoutFormation(
            checkIsLineupWithoutFormation(matchLineup?.team2_lineup, matchLineup?.team2_formation),
        )
    }, [matchLineup])

    useEffect(() => {
        setMetaData(selectedMatchDetails?.lineups_editor_meta)
    }, [selectedMatchDetails])

    const fixture = useMemo(
        () => ({
            team1: { id: selectedMatchDetails?.team1?.id, name: selectedMatchDetails?.team1?.name },
            team2: { id: selectedMatchDetails?.team2?.id, name: selectedMatchDetails?.team2?.name },
        }),
        [selectedMatchDetails],
    )

    const isSaveButtonDisabled = useMemo(
        () => !(checkAreAllPlayersIn(lineupData?.team1_lineup) && checkAreAllPlayersIn(lineupData?.team2_lineup)),
        [lineupData?.team1_lineup, lineupData?.team2_lineup],
    )

    if (isLoading) {
        return (
            <Center>
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" m="10rem" />
            </Center>
        )
    }

    // If there's no team name, just write Home Button, else Home Button - Team Name
    const homeButtonTitle = selectedMatchDetails?.team1?.name ? `Home - ${selectedMatchDetails?.team1?.name}` : 'Home'

    const awayButtonTitle = selectedMatchDetails?.team2?.name ? `Away - ${selectedMatchDetails?.team2?.name}` : 'Away'

    if (selectedMatchDetails?.id) {
        return (
            <>
                <Box w="100%">
                    <Heading as="h2" size="md" mb={1} minH="10">
                        Meta Data
                    </Heading>
                    <Box
                        w="100%"
                        alignItems="start"
                        borderStyle="solid"
                        borderWidth="1px"
                        borderColor="blackAlpha.50"
                        borderRadius="16"
                        px={{ base: 4, xl: 8 }}
                        py={4}
                    >
                        <CollapsableToDrawer>
                            <LineupsMetaData matchId={selectedMatchDetails?.id} metaData={metaData} />
                        </CollapsableToDrawer>
                    </Box>
                </Box>
                <Button
                    colorScheme="teal"
                    isDisabled={isSaveButtonDisabled}
                    onClick={handleSaveLineup}
                    isLoading={isPostDataLoading}>
                    Save Lineups
                </Button>
                <Tabs isFitted variant="line" w="100%">
                    <TabList>
                        <Tab>{homeButtonTitle}</Tab>
                        <Tab>{awayButtonTitle}</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel p={{ base: 0, xl: 4 }} pt={4}>
                            <LineupEditContainer
                                isHomeTeam={true}
                                isLineupWithoutFormation={isTeam1WithoutFormation}
                                isPreviousLineup={isTeam1PreviousLineup}
                                initialLineup={initialLineupData}
                                fixture={fixture}
                                initialMissingPlayers={missingPlayers?.team1MissingPlayers}
                            />
                        </TabPanel>
                        <TabPanel p={{ base: 0, xl: 4 }} pt={4}>
                            <LineupEditContainer
                                isHomeTeam={false}
                                isLineupWithoutFormation={isTeam2WithoutFormation}
                                isPreviousLineup={isTeam2PreviousLineup}
                                initialLineup={initialLineupData}
                                fixture={fixture}
                                initialMissingPlayers={missingPlayers?.team2MissingPlayers}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </>
        )
    }

    return (
        <Alert status="info">
            <AlertIcon />
            <AlertTitle>No match selected!</AlertTitle>
            Please select a match to continue.
        </Alert>
    )
}
