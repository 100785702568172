/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from '@tanstack/react-query'

import { baseStatsApiURL } from '@common/config'
import { TPlayer } from '@common/types'

type Player = {
    player: Omit<TPlayer, 'shirt_number'>
    shirt_number: string | number
}

type ApiResponse = {
    midfielders?: Player[]
    goalkeepers?: Player[]
    defenders?: Player[]
    attackers?: Player[]
}

async function fetchData(formattedTeamId?: string) {
    if (!formattedTeamId) {
        return []
    }

    const response = await fetch(
        `${baseStatsApiURL}/soccer/teams/squad/comsuperbetsport/en-GB?team_id=${formattedTeamId}`,
    )

    const json = await response.json()

    if (response.status === 204) {
        return []
    }

    if (response.status !== 200) {
        throw new Error(json?.message ?? json?.error ?? '¯\\_(ツ)_/¯')
    }

    return processPlayersData(json)
}

function formatShirtNumber(shirtNumber: string | number): number | '-' {
    if (typeof shirtNumber === 'string' && shirtNumber !== '-') {
        const parsed = parseInt(shirtNumber, 10)

        return Number.isNaN(parsed) ? '-' : parsed
    }

    return shirtNumber
}

function processPlayersData(json: ApiResponse): TPlayer[] {
    const allPlayers = [
        ...(json.midfielders || []),
        ...(json.goalkeepers || []),
        ...(json.defenders || []),
        ...(json.attackers || []),
    ]

    return allPlayers.map(({ player, shirt_number }) => ({
        id: player?.id,
        name: player?.name,
        shirt_number: formatShirtNumber(shirt_number),
    }))
}

export function useTeamSquad(teamId?: number | string | null, shouldFormatTeamId = false) {
    const formattedTeamId = shouldFormatTeamId ? `br:team:${teamId}` : String(teamId ?? '')

    const { data, isLoading, isError } = useQuery({
        queryKey: ['useTeamSquad', formattedTeamId],
        queryFn: () => fetchData(formattedTeamId),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })

    return {
        playersList: (data as TPlayer[]) || [],
        isLoading,
        isError,
        isSquadEmpty: !data,
    }
}
