import { Center, Text } from '@chakra-ui/react'

interface NoSubstitutionsProps {
    message: string
}

export function NoSubstitutions({ message }: NoSubstitutionsProps) {
    return (
        <Center bg="sp.background" h="100%" w="100%" borderRadius="16" borderWidth="1px">
            <Text px="3" py="1" bg="sp.white" borderRadius="md">
                {message}
            </Text>
        </Center>
    )
}
