import { HStack } from '@chakra-ui/react'
import { useRouter } from '@tanstack/react-router'
import { format } from 'date-fns'

import { useSearchState } from '@hooks/useSearchState'

import { editorPaths } from '@common/config'

import { DateSelect } from './DateSelect'
import { MatchSelect } from './MatchSelect'

export function EventPicker() {
    const [{ date = new Date() }, setSearchState] = useSearchState()
    const router = useRouter()

    const formattedDate = format(new Date(date), 'yyyy-MM-dd')

    const handleDateChange = (newDate: Date) => {
        const { pathname } = router.state.location

        const matchingEditor = Object.keys(editorPaths).find(editor => pathname.includes(editor))

        if (matchingEditor) {
            router.navigate({ to: `${editorPaths[matchingEditor]}/todo` })
        }

        setSearchState({ date: format(newDate, 'yyyy-MM-dd'), matchId: undefined })
    }

    return (
        <HStack justifyContent="space-between" gap={4} w="100%">
            <DateSelect date={new Date(date)} setDate={handleDateChange} />
            <MatchSelect date={formattedDate} />
        </HStack>
    )
}
