import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'

import { useSearchState } from '@hooks/useSearchState'

import { MatchEventEditorApiEvent } from '@common/types'

import { useMatchEventEditorData } from '@queries/useMatchEventEditorData'

type EventContext = {
    selectedEvent: MatchEventEditorApiEvent | null
    setSelectedEvent: (event: MatchEventEditorApiEvent | null) => void
}

const defaultValue: EventContext = {
    selectedEvent: null,
    setSelectedEvent: () => null,
}

export const SelectedEventContext = createContext(defaultValue)

SelectedEventContext.displayName = 'SelectedEventContext'

export function SelectedEventProvider({ children }: PropsWithChildren) {
    const { events } = useMatchEventEditorData()
    const [{ eventId }, setSearchState] = useSearchState()

    const selectedEvent = useMemo(() => {
        if (eventId) {
            return events.find(event => event.id === eventId) ?? null
        }

        return null
    }, [events, eventId])

    const setSelectedEvent = useCallback((event: MatchEventEditorApiEvent | null) => {
        if (event && event?.id) {
            setSearchState({ eventId: event.id })
        }
    }, [])

    return (
        <SelectedEventContext.Provider value={{ selectedEvent, setSelectedEvent }}>
            {children}
        </SelectedEventContext.Provider>
    )
}

export function useSelectedEventContext() {
    return useContext(SelectedEventContext)
}
