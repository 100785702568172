import { createIcon } from '@chakra-ui/react'

export const SuperbetLogo = createIcon({
    displayName: 'SuperbetLogo',
    viewBox: '0 -4 160 36',
    path: (
        <svg width="160px" height="36px" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9099 8.66601C13.781 7.159 13.1176 6.10387 11.0836 5.79286C9.1425 5.49329 8.22057 6.1201 8.06023 7.11936C7.87604 8.2693 8.64492 8.83047 10.6498 9.44901C11.5 9.71372 12.3512 9.97592 13.2034 10.2358C18.2522 11.7778 19.863 13.8523 19.4014 17.3848C18.894 21.3302 15.2805 24.014 8.76652 23.0269C2.28684 22.0309 -0.976826 18.8731 0.257426 13.9292C2.29943 14.2855 4.34327 14.6281 6.38873 14.9568C5.97741 16.5035 6.60311 17.855 9.61561 18.3176C11.9303 18.6695 13.0149 18.1096 13.1858 16.927C13.3353 15.8948 12.7477 15.2855 10.9969 14.7434C10.1573 14.4824 9.31869 14.2191 8.48109 13.9537C3.38918 12.3437 1.36441 9.94531 2.0027 6.29623C2.61592 2.81562 5.78368 0.131723 11.9007 1.07882C17.6862 1.95974 20.4026 4.92236 19.9049 9.47135C17.9053 9.21622 15.907 8.94779 13.9099 8.66601Z"
                fill="#EA060A"
            />
            <path
                d="M21.4432 15.7327C21.9811 11.5148 22.519 7.29708 23.0569 3.0791C25.0571 3.33423 27.0587 3.57595 29.0614 3.80407C28.5907 7.9373 28.1201 12.0708 27.6494 16.204C27.3664 18.6708 28.339 20.0202 30.6288 20.2651C32.9189 20.5076 34.1529 19.3932 34.3955 16.9223C34.8054 12.7825 35.2153 8.6428 35.6252 4.50298C37.6302 4.70145 39.6363 4.88663 41.6432 5.05816C41.2812 9.29476 40.9192 13.5314 40.5573 17.7679C40.0843 23.4754 36.4242 26.4569 30.0414 25.784C23.6598 25.0976 20.7038 21.4116 21.4432 15.7327Z"
                fill="#EA060A"
            />
            <path
                d="M42.725 26.5302C43.286 19.4487 43.847 12.3672 44.4081 5.28564C47.5835 5.5373 50.7609 5.75518 53.9398 5.9391C58.9115 6.22573 62.2172 9.19255 62.048 13.8532C61.8802 18.477 58.235 21.236 53.0721 20.9391C51.8639 20.8692 50.656 20.7946 49.4483 20.7152C49.311 22.8061 49.1736 24.8968 49.0362 26.9877C46.9317 26.8494 44.828 26.6969 42.725 26.5302ZM49.7821 15.636C50.746 15.6994 51.7101 15.7598 52.6743 15.817C54.5308 15.9267 55.556 15.0267 55.6331 13.5573C55.7084 12.1171 54.7956 11.0892 52.8681 10.9751C51.9451 10.9201 51.0224 10.8622 50.0997 10.8016C49.9938 12.413 49.8879 14.0246 49.7821 15.636Z"
                fill="#EA060A"
            />
            <path
                d="M63.4435 27.7122C63.6917 20.6128 63.94 13.5134 64.1882 6.41406C69.3914 6.59601 74.5971 6.68752 79.8027 6.68842C79.8024 8.426 79.802 10.1636 79.8017 11.9012C76.5715 11.9006 73.3412 11.8656 70.1115 11.7961C70.091 12.7464 70.0706 13.6968 70.0501 14.6472C72.809 14.7066 75.5682 14.7409 78.3275 14.7503C78.3218 16.4368 78.3161 18.1233 78.3103 19.8098C75.5205 19.8003 72.7307 19.7656 69.9414 19.7055C69.92 20.6967 69.8987 21.688 69.8774 22.6792C73.227 22.7511 76.5772 22.7871 79.9274 22.7868C79.9276 24.5244 79.9277 26.262 79.9279 27.9996C74.4323 28.0001 68.9366 27.9043 63.4435 27.7122Z"
                fill="#EA060A"
            />
            <path
                d="M101.705 22.0915C101.788 23.8577 101.871 25.6242 101.953 27.3905C101.445 27.5681 100.707 27.6936 99.9978 27.7237C96.0653 27.8917 92.8179 27.0303 90.5282 21.6537C90.4387 21.4512 90.3492 21.2488 90.2598 21.0464C89.6665 21.0596 89.0731 21.0718 88.4796 21.0829C88.5219 23.3618 88.5641 25.6407 88.6064 27.9196C86.4988 27.9587 84.391 27.9837 82.2831 27.9944C82.2468 20.8908 82.2107 13.7872 82.1744 6.68352C85.4703 6.66669 88.7659 6.61362 92.0609 6.52421C97.0693 6.38766 100.63 8.72384 100.865 13.5661C101.019 16.8426 99.4098 18.9798 96.3899 19.856C97.8425 22.2593 99.414 22.2569 100.688 22.1995C101.07 22.1823 101.451 22.134 101.705 22.0915ZM88.304 11.6094C88.3326 13.1526 88.3612 14.6956 88.3898 16.2388C89.5118 16.218 90.6338 16.1931 91.7557 16.1641C93.6126 16.1151 94.4941 15.1332 94.4492 13.7548C94.4052 12.4055 93.4715 11.4875 91.5425 11.5378C90.4631 11.5655 89.3836 11.5894 88.304 11.6094Z"
                fill="#EA060A"
            />
            <path
                d="M104.085 27.3779C103.72 20.2836 103.354 13.1893 102.989 6.09496C106.169 5.93104 109.347 5.7334 112.524 5.50193C117.587 5.12852 120.37 7.13458 120.689 10.5896C120.917 13.0937 119.304 14.8411 117.427 15.5175C120.026 15.9807 121.862 17.287 122.11 19.9746C122.478 23.9086 119.843 26.3245 114.78 26.7046C111.217 26.9696 107.652 27.1941 104.085 27.3779ZM109.565 14.1583C110.578 14.0924 111.591 14.0232 112.604 13.9506C114.108 13.8425 114.799 12.9877 114.702 11.7349C114.605 10.4813 113.801 9.77572 112.312 9.8828C111.309 9.95479 110.305 10.0234 109.301 10.0886C109.389 11.4452 109.477 12.8017 109.565 14.1583ZM110.118 22.6648C111.139 22.5985 112.161 22.5286 113.181 22.4556C114.937 22.3294 115.771 21.4933 115.666 20.1489C115.561 18.803 114.617 18.1101 112.879 18.2348C111.868 18.3072 110.856 18.3763 109.844 18.4422C109.935 19.8498 110.027 21.2573 110.118 22.6648Z"
                fill="#EA060A"
            />
            <path
                d="M124.691 25.8614C124.014 18.7899 123.338 11.7185 122.661 4.64703C127.798 4.15551 132.928 3.5753 138.05 2.90627C138.275 4.62912 138.5 6.35226 138.725 8.07511C135.567 8.48763 132.405 8.86686 129.241 9.21272C129.344 10.1577 129.447 11.1025 129.551 12.0475C132.298 11.7473 135.043 11.4221 137.785 11.0719C137.999 12.7448 138.212 14.4177 138.426 16.0906C135.653 16.4446 132.878 16.7735 130.1 17.0771C130.208 18.0626 130.316 19.0484 130.423 20.0338C133.758 19.6693 137.091 19.2686 140.419 18.8317C140.646 20.5545 140.872 22.2773 141.098 24.0002C135.637 24.7169 130.167 25.3373 124.691 25.8614ZM148.625 22.9502C147.841 17.6417 147.057 12.3332 146.273 7.02465C144.408 7.30014 142.542 7.56389 140.675 7.81609C140.442 6.09408 140.21 4.37213 139.978 2.65018C145.699 1.87766 151.408 0.994289 157.104 0C157.403 1.71165 157.701 3.42342 158 5.13518C156.11 5.46506 154.219 5.78283 152.326 6.08869C153.182 11.3862 154.037 16.6835 154.893 21.9811C152.805 22.3184 150.716 22.6413 148.625 22.9502Z"
                fill="#EA060A"
            />
        </svg>
    ),
})
