import { Button } from './button'
import { Checkbox } from './checkbox'
import { Drawer } from './drawer'
import { FormLabel } from './formLabel'
import { Input } from './input'
import { Popover } from './popover'

export const components = {
    Button,
    Input,
    FormLabel,
    Drawer,
    Checkbox,
    Popover,
}
