import { ReactNode, useEffect, useMemo, useState } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { Alert, AlertIcon, AlertTitle, Box, BoxProps, IconButton } from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'

import { DataTable } from '@components/DataTable/DataTable'

import { useSearchState } from '@hooks/useSearchState'

import { EnrichedMatchEditorApiEvent, MatchEventEditorApiEvent, MatchEventEditorEventType } from '@common/types'

import { useMatchEditorAlertDialogContext } from '@contexts/MatchEditorAlertDialogContext'
import { useSelectedEventContext } from '@contexts/SelectedEventContext'

import { useMatchEventEditorData } from '@queries/useMatchEventEditorData'

const columnHelper = createColumnHelper<EnrichedMatchEditorApiEvent>()

function makePrettierLabel(label: string) {
    const words = label.split('_')
    const prettierLabel = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

    return prettierLabel
}

function getMatchEditorEventTypeLabel(eventType: number) {
    const eventTypeLabel = Object.keys(MatchEventEditorEventType).find(
        key => MatchEventEditorEventType[key as keyof typeof MatchEventEditorEventType] === eventType,
    )

    if (eventTypeLabel) {
        return makePrettierLabel(eventTypeLabel)
    }

    return '-'
}

type RemovableRowProps = {
    rowId: number
    children: ReactNode
} & BoxProps

export function RemovableRow({ rowId, children, ...props }: RemovableRowProps) {
    const { selectedEvent } = useSelectedEventContext()
    const [isMouseOver, setIsMouseover] = useState(false)
    const { remove } = useMatchEventEditorData()

    const { onOpen, setOnCloseCallback } = useMatchEditorAlertDialogContext()

    const shouldRenderDeleteButton = selectedEvent?.id === rowId || isMouseOver

    return (
        <Box
            onMouseEnter={() => setIsMouseover(true)}
            onMouseLeave={() => setIsMouseover(false)}
            as="span"
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            gap={4}
            w="100%"
            position="relative"
            h="24px"
            borderRadius="16"
            {...props}>
            <Box as="span" textAlign="start" flex={5}>
                {children}
            </Box>
            {shouldRenderDeleteButton && (
                <IconButton
                    flex={1}
                    colorScheme="red"
                    aria-label="Delete event"
                    size="xs"
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={() => {
                        setOnCloseCallback(() => remove(rowId))

                        onOpen()
                    }}
                    icon={<DeleteIcon />}
                />
            )}
        </Box>
    )
}

export function EventTable() {
    const { events } = useMatchEventEditorData()
    const { selectedEvent, setSelectedEvent } = useSelectedEventContext()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [_, setSearchState] = useSearchState()

    const handleRowClick = (rowEvent: MatchEventEditorApiEvent) => {
        setSelectedEvent(rowEvent)
    }

    useEffect(() => {
        const updatedEvent = events.find(event => event.id === selectedEvent?.id)

        if (updatedEvent) {
            setSelectedEvent(updatedEvent as MatchEventEditorApiEvent)
        }

        if (!events?.length) {
            setSearchState({ eventId: undefined })
        }
    }, [events, selectedEvent])

    const columns = useMemo(
        () => [
            columnHelper.accessor('match_time', {
                id: 'match_time',
                cell: value => value.getValue(),
                header: 'Time',
            }),
            columnHelper.accessor('team_name', {
                cell: value => (
                    <Box as="span" isTruncated={true} maxInlineSize="200px">
                        {value.getValue()}
                    </Box>
                ),
                header: 'Team',
            }),
            columnHelper.accessor('type', {
                cell: value => {
                    const isGoal = value.getValue() === MatchEventEditorEventType.goal

                    if (isGoal) {
                        return (
                            <RemovableRow rowId={value.row.original.id}>
                                {getMatchEditorEventTypeLabel(value.getValue())} (
                                {value.row.original.match_score?.team1}:{value.row.original.match_score?.team2})
                            </RemovableRow>
                        )
                    }

                    return (
                        <RemovableRow rowId={value.row.original.id} minInlineSize="120px">
                            {getMatchEditorEventTypeLabel(value.getValue())}
                        </RemovableRow>
                    )
                },
                header: 'Event',
            }),
        ],
        [],
    )

    if (!events?.length) {
        return (
            <Alert status="info">
                <AlertIcon />
                <AlertTitle>No events to edit!</AlertTitle>
                Add new event or select a different match.
            </Alert>
        )
    }

    return (
        <Box overflowY="auto" height="100%">
            <DataTable<EnrichedMatchEditorApiEvent>
                columns={columns}
                data={events}
                onRowClick={handleRowClick}
                selectedRow={selectedEvent?.id}
            />
        </Box>
    )
}
