import { useRef } from 'react'
import {
    AlertDialog as AlertDialogChakra,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react'

import { useMatchEditorAlertDialogContext } from '@contexts/MatchEditorAlertDialogContext'

export function AlertDialog() {
    const { isOpen, onClose, onToggle } = useMatchEditorAlertDialogContext()
    const cancelRef = useRef(null)

    return (
        <AlertDialogChakra isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onToggle}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete Event
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Are you sure you want to delete this event? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onToggle}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={onClose} ml={3}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialogChakra>
    )
}
