import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { fetchAuthSession } from 'aws-amplify/auth'

import { ApiErrorMessage } from '@common/types'

import { queryClientConfig } from './config'

async function invalidateProtectedQueries() {
    await fetchAuthSession({ forceRefresh: true })

    const userRefreshed = await queryClient.ensureQueryData({ queryKey: ['user', 'accessToken'] })

    if (userRefreshed) {
        await queryClient.invalidateQueries({ queryKey: ['events', 'match', 'teams'] })
    }
}

export const queryClient = new QueryClient({
    ...queryClientConfig,
    queryCache: new QueryCache({
        onError: async (error, query) => {
            if (error.message === ApiErrorMessage.UNAUTHORIZED) {
                await invalidateProtectedQueries()
            }
        },
    }),
    mutationCache: new MutationCache({
        onError: async error => {
            if (error.message === ApiErrorMessage.UNAUTHORIZED) {
                await invalidateProtectedQueries()
            }
        },
    }),
})
