import { useEffect, useState } from 'react'
import { Text, VStack } from '@chakra-ui/react'

import { PlayerJersey } from '../Icons/PlayerJersey'

interface PlayerProps {
    player: any
    isHomeTeam: boolean
    isGoalKeeper?: boolean
    isDragOverlay?: boolean
    isCurrentActivePlayer?: boolean
    isSquadPlayer?: boolean
    isPlayerActiveDroppable?: boolean
    variant?: 'dark' | 'light'
    handlePlayerClick?: () => void
}

const playerJerseyColors = {
    homeTeam: '#EA060A',
    awayTeam: '#3B61DB',
    gkHomeTeam: '#333333',
    gkAwayTeam: '#FFCE05',
}

export function PlayerBasicInfo({
    player,
    isHomeTeam,
    isGoalKeeper,
    isDragOverlay = false,
    isCurrentActivePlayer = false,
    isSquadPlayer = false,
    isPlayerActiveDroppable = false,
    handlePlayerClick,
    variant = 'dark',
}: PlayerProps) {
    const [jerseyColor, setJerseyColor] = useState<string>(playerJerseyColors.homeTeam)

    useEffect(() => {
        if (isGoalKeeper) {
            setJerseyColor(isHomeTeam ? playerJerseyColors.gkHomeTeam : playerJerseyColors.gkAwayTeam)
        } else {
            setJerseyColor(isHomeTeam ? playerJerseyColors.homeTeam : playerJerseyColors.awayTeam)
        }
    }, [isHomeTeam])

    const opacity = (isPlayerActiveDroppable && !isSquadPlayer) || (!isDragOverlay && isCurrentActivePlayer) ? 0.4 : 1

    return (
        <VStack
            cursor={isDragOverlay ? 'grabbing' : 'grab'}
            role="button"
            spacing={0.5}
            alignItems="center"
            w="3.8rem"
            h="4.5rem"
            position="relative"
            opacity={opacity}
            transition="opacity 0.5s"
            color={isDragOverlay ? 'black' : 'white'}
            onClick={handlePlayerClick}
            style={{ scale: isDragOverlay ? '1.15' : '1' }}
        >
            <PlayerJersey jerseyColor={jerseyColor} shirtNumber={player.shirt_number} />
            <Text
                fontSize="0.625rem"
                fontWeight={isDragOverlay ? 'bold' : 'semibold'}
                noOfLines={2}
                lineHeight="short"
                userSelect="none"
                textAlign="center"
                transition="font-weight 0.4s, color 0.5s"
                color={variant === 'dark' || isCurrentActivePlayer ? 'black' : 'white'}
            >
                {player.name}
            </Text>
        </VStack>
    )
}
