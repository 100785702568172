import { createContext, useContext, useRef } from 'react'
import { useDisclosure } from '@chakra-ui/react'

const MatchEditorAlertDialogContext = createContext<{
    onOpen: () => void
    onClose: () => void
    setOnCloseCallback: (callback: (() => void) | null) => void
    isOpen: boolean
    onToggle: () => void
} | null>(null)

export function MatchEditorAlertDialogContextProvider({ children }) {
    const disclosure = useDisclosure({
        id: 'match-editor-alert-dialog',
    })

    const onCloseCallbackRef = useRef<(() => void) | null>(null)

    const setOnCloseCallback = (callback: (() => void) | null) => {
        onCloseCallbackRef.current = callback
    }

    const onClose = () => {
        if (typeof onCloseCallbackRef.current === 'function') {
            onCloseCallbackRef.current()
        }

        disclosure.onClose()
    }

    return (
        <MatchEditorAlertDialogContext.Provider value={{ ...disclosure, onClose, setOnCloseCallback }}>
            {children}
        </MatchEditorAlertDialogContext.Provider>
    )
}

export function useMatchEditorAlertDialogContext() {
    const context = useContext(MatchEditorAlertDialogContext)

    if (!context) {
        throw new Error('useMatchEditorAlertDialogContext must be used within a MatchEditorAlertDialogContextProvider')
    }

    return context
}
