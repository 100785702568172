import { FixtureLineup, TPlayer, TTeamLineupData } from '@/common/types'
import { getLineup } from '@/common/utils'

export function useFixtureLineup(team: 'team1' | 'team2'): { getTeamData: (matchLineup) => TTeamLineupData } {
    const getLineupAndSubs = (
        lineup: any[],
        formation: string | null,
    ): { lineup: TPlayer[][]; substitutions: TPlayer[] } => {
        let lineupPlayers: any[] = []
        let subPlayers: any[] = []
        let formattedLineup: any[] = []

        if (lineup?.length) {
            lineupPlayers = lineup.filter(player => player.starter === true).sort((a, b) => a.order - b.order)

            subPlayers = lineup.filter(player => player.starter === false)
        }

        formattedLineup = formation ? getLineup(lineupPlayers, formation).lineup : [lineupPlayers]

        return { lineup: formattedLineup, substitutions: subPlayers }
    }

    const getTeamData = (matchLineup: FixtureLineup) =>
        team === 'team1'
            ? {
                lineup: getLineupAndSubs(matchLineup?.team1_lineup, matchLineup?.team1_formation)?.lineup ?? [],
                formation: matchLineup?.team1_formation ?? null,
                substitutions:
                    getLineupAndSubs(matchLineup?.team1_lineup, matchLineup?.team1_formation)?.substitutions ?? [],
            }
            : {
                lineup: getLineupAndSubs(matchLineup?.team2_lineup, matchLineup?.team2_formation)?.lineup ?? [],
                formation: matchLineup?.team2_formation ?? null,
                substitutions:
                    getLineupAndSubs(matchLineup?.team2_lineup, matchLineup?.team2_formation)?.substitutions ?? [],
            }

    return { getTeamData }
}
