import { Container } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

import { ModifiedInEnum, TModifiedInEnum } from '@common/types'

type ModifiedInSelectProps = {
    modifiedIn?: TModifiedInEnum | null
    handleModifiedInChange: (modifiedIn: TModifiedInEnum) => void
}
export function ModifiedInSelect({ modifiedIn, handleModifiedInChange }: ModifiedInSelectProps) {
    const options = Object.values(ModifiedInEnum).map(value => ({
        value,
        label: value.replace('_', ' '),
    }))

    const changeModifiedIn = event => {
        if (modifiedIn !== event?.value) {
            handleModifiedInChange(event?.value)
        }
    }

    return (
        <Container maxW="sm" m={0} p={0}>
            <Select
                placeholder="Modified in..."
                onChange={e => {
                    changeModifiedIn(e)
                }}
                options={options}
                size="sm"
                value={modifiedIn && { label: modifiedIn.replace('_', ' '), value: modifiedIn }}
            />
        </Container>
    )
}
