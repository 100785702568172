import { TPlayer } from './types'

export const getLineup = (lineup: (TPlayer | null)[], formation: string) => {
    const formationArray = formation
        ?.split('-')
        .map(value => parseInt(value, 10))
        .filter(value => !Number.isNaN(value))

    const formattedLineup: any[] = []

    const goalKeeper = lineup?.filter((player, index) => index === 0)
    const formationWithoutGoalKeeper = lineup?.filter((player, index) => index !== 0)

    let playerCount = 0

    if (goalKeeper) {
        formattedLineup.push(goalKeeper)
    }

    if (formationArray?.length) {
        formationArray?.forEach(formationNumber => {
            const previousPLayerIndex = playerCount

            playerCount += formationNumber

            formattedLineup.push(formationWithoutGoalKeeper.slice(previousPLayerIndex, playerCount))
        })
    }

    return { lineup: formattedLineup }
}

export const getIsStageEnv = () => {
    const currentWindowOrigin = window.location.origin

    const isStageEnv = currentWindowOrigin.includes('stage') || currentWindowOrigin.includes('localhost')

    return isStageEnv
}

export const getLineupsCache = () => {
    const currentCache = localStorage.getItem('LineupData')

    return currentCache && JSON.parse(currentCache)
}

export const getLineupFromCache = (matchId?: string | null) => matchId && getLineupsCache()?.[matchId]

export const deleteLineupFromCache = (matchId: string) => {
    const unsavedLineups = getLineupsCache()
    if (getLineupFromCache(matchId)) {
        delete unsavedLineups[matchId]
    }

    setLineupCache(unsavedLineups)
}

export const setLineupCache = lineupData => {
    localStorage.setItem('LineupData', JSON.stringify(lineupData))
}

export const removePlayerFromList = (players, playerIdToRemove) =>
    Object.keys(players ?? []).reduce((updatedPlayers, key) => {
        updatedPlayers[key] = players[key].filter(player => player.name !== playerIdToRemove)

        return updatedPlayers
    }, {})
