import { SVGProps } from 'react'

export default function SvgClose(props: SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                d="M17.53 7.53a.75.75 0 0 0-1.06-1.06l1.06 1.06Zm-1.06 10a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM7.53 6.47a.75.75 0 0 0-1.06 1.06l1.06-1.06Zm-1.06 10a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm6.06-3.94 5-5-1.06-1.06-5 5 1.06 1.06Zm-1.06 0 5 5 1.06-1.06-5-5-1.06 1.06Zm1.06-1.06-5-5-1.06 1.06 5 5 1.06-1.06Zm-1.06 0-5 5 1.06 1.06 5-5-1.06-1.06Z"
                fill="currentColor"
            />
        </svg>
    )
}
