import { DeleteIcon } from '@chakra-ui/icons'
import { Box, Container, Divider, Flex, IconButton } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

import { SortableItem } from '@components/DragAndDrop/SortableItem'

import { MissingPlayer, MissingPlayerReason } from '@common/types'

import { useMissingPlayers } from '@queries/useMissingPlayers'

import { ListPlayerBasicInfo } from './ListPlayerBasicInfo'

interface ListPlayerProps {
    player: MissingPlayer
    index: number | string
    id: string
    isCurrentActivePlayer: boolean
    updatePlayer: (player: MissingPlayer) => void
    deletePlayer: (player: MissingPlayer) => void
    handlePlayerClick?: (player: MissingPlayer) => void
}

const MissingPlayerReasonTypesMapping = {
    [MissingPlayerReason.MISSINGPLAYERREASON_INJURY]: 'injury_types',
    [MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION]: 'suspension_types',
}

const getPrettyString = (value?: string) => value?.replace(/_/g, ' ')

export function ListPlayer({
    player,
    index,
    id,
    isCurrentActivePlayer = false,
    updatePlayer,
    deletePlayer,
    handlePlayerClick,
}: ListPlayerProps) {
    const { missingPlayersTypes, isMissingPlayerTypesLoading } = useMissingPlayers()

    const label =
        getPrettyString(
            missingPlayersTypes?.[MissingPlayerReasonTypesMapping[player?.reason]]?.find(
                type => type[1] === player?.type,
            )?.[0],
        ) ?? 'no reason'

    const selectReasonTypeOptions = missingPlayersTypes?.[MissingPlayerReasonTypesMapping[player?.reason]]?.map(
        type => ({
            value: type[1],
            label: getPrettyString(type[0].replace(/_/g, ' ')),
        }),
    )

    const handleTypeChange = event => {
        updatePlayer({
            ...player,
            type: event.value,
        })
    }

    return (
        <SortableItem key={`${id}-${index}`} id={id} data={{ player }}>
            {player ? (
                <Flex
                    cursor="grab"
                    p={3}
                    gap={3}
                    alignItems="center"
                    justifyContent="space-between"
                    opacity={isCurrentActivePlayer ? 0.5 : 1}
                    transition="opacity 0.5s"
                >
                    <ListPlayerBasicInfo player={player} handlePlayerClick={handlePlayerClick} />
                    <Flex gap={4}>
                        <Container width="100%" maxW="200px" m={0} p={0} backgroundColor="whiteAlpha.700">
                            <Select
                                options={selectReasonTypeOptions}
                                size="sm"
                                onChange={e => handleTypeChange(e)}
                                value={{ label, value: player.type }}
                                isLoading={isMissingPlayerTypesLoading}
                            />
                        </Container>
                        <IconButton
                            variant="outline"
                            flex={1}
                            colorScheme="red"
                            aria-label="Delete event"
                            size="sm"
                            icon={<DeleteIcon />}
                            onClick={() => deletePlayer(player)}
                        />
                    </Flex>
                </Flex>
            ) : (
                /* this is a temp hack for creating the sortableItem ref if the list is empty
                -> I'll think of a better solution */
                <Box minH="100px" />
            )}
            <Divider borderColor="blackAlpha.50" />
        </SortableItem>
    )
}
