import { useQuery } from '@tanstack/react-query'

import { baseStatsApiURL_NO_CACHE } from '@common/config'

import { useUser } from '../queries/useUser'
import { toast } from '@/utils/toast'

async function fetchData(team1Id?: string | null, team2Id?: string | null, accessToken?: string) {
    if (!team1Id || !team2Id) {
        return {}
    }

    if (!accessToken) {
        toast({
            position: 'top',
            description: 'Missing user access token!',
            status: 'error',
            duration: 9000,
            isClosable: true,
        })

        throw new Error('No access token provided')
    }

    const response = await fetch(
        `${baseStatsApiURL_NO_CACHE}/default/data-provider/api/last-formation?team1_id=${team1Id}&team2_id=${team2Id}`,
        {
            headers: {
                Authentication: accessToken,
            },
        },
    )

    const json = await response.json()

    if (response.status !== 200) {
        toast?.({
            position: 'top',
            description: 'Error fetching previous lineup!',
            status: 'error',
            duration: 9000,
            isClosable: true,
        })
    }

    return json
}

export function usePreviousLineup(team1IdArg?: string | null, team2IdArg?: string | null) {
    const { userData } = useUser()
    const {
        data: previousLineup,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['usePreviousLineup', team1IdArg, team2IdArg, userData.accessToken, toast],
        queryFn: () => fetchData(team1IdArg, team2IdArg, userData.accessToken),
        refetchOnWindowFocus: false,
    })

    return { previousLineup, isLoading, isError }
}
