/* eslint-disable @typescript-eslint/naming-convention */
import { LegacyRef, useEffect, useMemo, useRef, useState } from 'react'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    Modal,
    ModalOverlay,
    ScaleFade,
    Text,
    useBreakpointValue,
    useDisclosure,
    useToast,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'
import {
    Active,
    DndContext,
    DragOverEvent,
    DragOverlay,
    Over,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import { MissingPlayersEditor } from '@components/MissingPlayersEditor/MissingPlayersEditor'

import { useFixtureLineup } from '@hooks/useFixtureLineup'

import {
    CurrentActivePlayer,
    FixtureLineup,
    MissingPlayerReason,
    MissingPlayerReasonAreaIds,
    MissingPlayersList,
    TMissingPlayerReasonAreaIds,
    TPlayer,
} from '@common/types'
import {
    deleteLineupFromCache,
    getLineupFromCache,
    getLineupsCache,
    removePlayerFromList,
    setLineupCache,
} from '@common/utils'

import { LineupPreviewHeader } from '../LineupPreviewHeader'
import { NoSubstitutions } from '../NoSubstitutions'
import { PlayerBasicInfo } from '../PlayerBasicInfo'
import { FormationSelect } from './FormationSelect'
import { LineupEditor } from './LineupEditor'
import { LineupListEditor } from './LineupListEditor'
import { SquadList } from './SquadList'
import { SubstitutionsEditor } from './SubstitutionsEditor'
import { AddPlayerModal, TPlayerModalProps } from '@/components/LineupEditor/AddPlayerModal'
import { useLineupDataContext } from '@/contexts/LineupDataContext'

interface LineupEditModalContentProps {
    isHomeTeam: boolean
    isLineupWithoutFormation: boolean
    isPreviousLineup: boolean
    initialLineup?: FixtureLineup
    fixture: {
        team1: { id?: string | null; name?: string | null }
        team2: { id?: string | null; name?: string | null }
    }
    initialMissingPlayers?: MissingPlayersList
}

export type NewLineup = (TPlayer | null)[][]
export type NewSubs = TPlayer[] | any[]

const getTransformedLineup = (lineup: NewLineup, subs: NewSubs) => {
    const lineupPlayers = lineup.flat().map((player, index) => ({ ...player, order: index + 1, starter: true }))
    const subPlayers = subs ? subs.filter(player => player !== null) : []

    return lineupPlayers.concat(subPlayers).sort()
}

const setCachedLineup = (currentLineup: FixtureLineup, changes) => {
    const currentCacheJson = getLineupsCache()

    const key = currentLineup.match_id ?? ''

    const newCache = {
        ...currentCacheJson,
        [key]: {
            ...currentLineup,
            ...changes,
        },
    }

    setLineupCache(newCache)
}

export function LineupEditContainer({
    isHomeTeam,
    isLineupWithoutFormation,
    isPreviousLineup,
    initialLineup,
    fixture,
    initialMissingPlayers,
}: LineupEditModalContentProps) {
    const [currentActivePlayer, setCurrentActivePlayer] = useState<CurrentActivePlayer | null>()
    const [currentEditPlayer, setCurrentEditPlayer] = useState<CurrentActivePlayer>()
    const [isExistingEditPlayer, setIsExistingEditPlayer] = useState<boolean>()
    const [addedSquadPlayers, setAddedSquadPlayers] = useState<TPlayer[]>([])

    const { lineupData, setLineupData } = useLineupDataContext()

    const { isOpen: isModalOpen, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure()
    const modalSize = useBreakpointValue({ base: 'full', md: 'md' })

    const { getTeamData } = useFixtureLineup(isHomeTeam ? 'team1' : 'team2')

    const [newSubs, setNewSubs] = useState<NewSubs>([])
    const [newFormation, setNewFormation] = useState<string | null>(null)
    const [newLineup, setNewLineup] = useState<NewLineup>([])
    const [newMissingPlayers, setNewMissingPlayers] = useState<MissingPlayersList | undefined>(initialMissingPlayers)
    const [isLineupEmpty, setIsLineupEmpty] = useState<boolean>(false)

    const teamId = isHomeTeam ? fixture?.team1.id : fixture?.team2.id

    const teamName = isHomeTeam ? fixture.team1.name : fixture.team2.name

    const modalRef = useRef<HTMLElement | null>(null)

    const [takenShirtNumbersLineup, setTakenShirtNumbersLineup] = useState<number[]>([])
    const [takenShirtNumbersSubs, setTakenShirtNumbersSubs] = useState<number[]>([])
    const [takenShirtNumbers, setTakenShirtNumbers] = useState<number[]>([])

    useEffect(() => {
        setNewMissingPlayers(initialMissingPlayers)
    }, [initialMissingPlayers])

    const toast = useToast()

    const checkIsEmptyLineup = lineup => lineup?.flat().filter(p => p !== null && p.name).length === 0

    const getUnsavedLineupChanges = (unsaved: { lineup?: NewLineup; subs?: NewSubs }) => {
        const unsavedLineup = unsaved.lineup ?? newLineup
        const unsavedSubs = unsaved.subs ?? newSubs

        const filteredSubs = unsavedSubs.filter(
            player =>
                !unsavedLineup
                    .flat()
                    .find(
                        lineupPlayer =>
                            (lineupPlayer?.id && lineupPlayer?.id === player?.id) ||
                            lineupPlayer?.name === player?.name,
                    ),
        )

        const unsavedTransformedLineup = getTransformedLineup(unsavedLineup, filteredSubs)

        return isHomeTeam ? { team1_lineup: unsavedTransformedLineup } : { team2_lineup: unsavedTransformedLineup }
    }

    const handleSubsChanges = (subs: NewSubs) => {
        setNewSubs(subs)

        setCachedLineup(lineupData, getUnsavedLineupChanges({ subs }))
    }

    const handleLineupChanges = (lineup: NewLineup, subs?: NewSubs) => {
        setNewLineup(lineup)

        setCachedLineup(lineupData, getUnsavedLineupChanges({ lineup, subs }))
    }

    const handleFormationChanges = (formation: string | null) => {
        setNewFormation(formation)

        setCachedLineup(lineupData, isHomeTeam ? { team1_formation: formation } : { team2_formation: formation })
    }

    useEffect(() => {
        const cachedLineup = lineupData?.match_id && getLineupFromCache(lineupData.match_id)

        const lineupInfo = getTeamData(cachedLineup || initialLineup)

        if (lineupInfo.formation !== null) {
            setNewLineup(lineupInfo?.lineup)
        } else {
            setNewLineup([
                ...lineupInfo.lineup,
                [...new Array(Math.max(11 - lineupInfo.lineup[0].length, 0)).fill(null)],
            ])
        }

        setNewSubs([
            ...lineupInfo.substitutions,
            ...new Array(Math.max(20 - lineupInfo.substitutions.length, 0)).fill(null),
        ])

        setNewFormation(lineupInfo?.formation)
    }, [initialLineup])

    useEffect(() => {
        const takenShirtNumbersArray: number[] = []

        if (checkIsEmptyLineup(newLineup)) {
            setTakenShirtNumbersLineup([])
        } else {
            newLineup.flat().forEach(player => {
                if (typeof player?.shirt_number === 'number') {
                    takenShirtNumbersArray.push(player?.shirt_number)

                    setTakenShirtNumbersLineup(takenShirtNumbersArray)
                }
            })
        }
    }, [newLineup])

    useEffect(() => {
        const takenShirtNumbersArray: number[] = []

        if (checkIsEmptyLineup(newSubs)) {
            setTakenShirtNumbersSubs([])
        } else {
            newSubs.flat().forEach(subPlayer => {
                if (subPlayer?.shirt_number) {
                    takenShirtNumbersArray.push(subPlayer?.shirt_number)

                    setTakenShirtNumbersSubs(takenShirtNumbersArray)
                }
            })
        }
    }, [newSubs])

    useEffect(() => {
        setTakenShirtNumbers([...takenShirtNumbersLineup, ...takenShirtNumbersSubs])
    }, [takenShirtNumbersLineup, takenShirtNumbersSubs])

    useEffect(() => {
        if (isHomeTeam) {
            setLineupData(previousLineupData => ({
                ...previousLineupData,
                team1_lineup: getTransformedLineup(newLineup, newSubs),
                team1_formation: newFormation,
            }))
        } else {
            setLineupData(previousLineupData => ({
                ...previousLineupData,
                team2_lineup: getTransformedLineup(newLineup, newSubs),
                team2_formation: newFormation,
            }))
        }

        setIsLineupEmpty(checkIsEmptyLineup(newLineup) && checkIsEmptyLineup(newSubs))
    }, [newLineup, newSubs, newFormation])

    const handleAddPlayer = (playerData: TPlayerModalProps) => {
        if (addedSquadPlayers.some(player => player.name === playerData.player?.name)) {
            toast({
                position: 'top',
                description: 'Player with the same name already exists!',
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        } else {
            addPlayerToSquadList(playerData)

            if (playerData.position?.index !== undefined) {
                setSubstitutionPlayer(playerData.position.index, playerData.player)
            }

            if (playerData.position?.colIndex !== undefined && playerData.position?.rowIndex !== undefined) {
                setLineupPlayer(playerData.position.rowIndex, playerData.position.colIndex, playerData.player)
            }

            setIsExistingEditPlayer(false)

            onCloseModal()
        }
    }

    const checkIsShirtNumberTaken = player => {
        const isPlayerInLineup =
            newLineup.flat().filter(playerData => playerData?.name === player.name).length ||
            newSubs.flat().filter(playerData => playerData?.name === player.name).length

        return isPlayerInLineup ? false : takenShirtNumbers.includes(player.shirt_number)
    }

    const deleteLineup = () => {
        const lineup = newLineup.map(row =>
            row.map(() => ({
                starter: true,
            })),
        )

        const subs = newSubs.map(() => null)

        handleSubsChanges(subs)

        handleLineupChanges(lineup, [])
    }

    const filterSubs = (playerData, positionData) => {
        if (newSubs.find(subsPlayer => subsPlayer?.name === playerData?.player?.name)) {
            const currentSubs = [...newSubs]

            const currentSubsWithoutActivePlayer = currentSubs.map((player, index) =>
                player?.name === playerData?.player?.name && index !== positionData.index ? null : player,
            )

            handleSubsChanges(currentSubsWithoutActivePlayer)
        }
    }

    const extractBasicInfoFromPlayer = (player: TPlayer) => ({
        id: player.id,
        name: player.name,
        shirt_number: player.shirt_number,
    })

    const setLineupPlayer = (rowIndex?: number, colIndex?: number, player?: TPlayer) => {
        if (rowIndex === undefined || colIndex === undefined) {
            return
        }

        const currentLineup = [...newLineup]

        currentLineup[rowIndex][colIndex] = { ...(player && extractBasicInfoFromPlayer(player)), starter: true }

        handleLineupChanges(currentLineup)
    }

    const setSubstitutionPlayer = (index?: number, player?: TPlayer, subs?: any[]) => {
        if (index === undefined) {
            return
        }

        const currentSubs = subs || [...newSubs]

        currentSubs[index] = player?.name
            ? { starter: false, order: null, ...(player && extractBasicInfoFromPlayer(player)) }
            : null

        handleSubsChanges(currentSubs)
    }

    const hasUnsavedChanges = useMemo(
        () => Boolean(lineupData?.match_id && getLineupFromCache(lineupData.match_id)),
        [getLineupFromCache(lineupData?.match_id)],
    )

    function getMissingPlayerReasonId(missingPlayerReasonArea: TMissingPlayerReasonAreaIds) {
        switch (missingPlayerReasonArea) {
            case MissingPlayerReasonAreaIds[MissingPlayerReason.MISSINGPLAYERREASON_INJURY]:
                return MissingPlayerReason.MISSINGPLAYERREASON_INJURY

            case MissingPlayerReasonAreaIds[MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION]:
                return MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION

            default:
                return null
        }
    }

    function handleMissingPlayersSortable(over: Over | null, active: Active) {
        const sortableOverContainerId = over?.data?.current?.sortable?.containerId || over?.id

        const sortableActiveContainerId = active?.data?.current?.sortable?.containerId

        const activeContainerReasonId = getMissingPlayerReasonId(sortableActiveContainerId)
        const overContainerReasonId = getMissingPlayerReasonId(sortableOverContainerId)

        if (!sortableOverContainerId || sortableOverContainerId !== sortableActiveContainerId) {
            return
        }

        const activeIndex = ((activeContainerReasonId && newMissingPlayers?.[activeContainerReasonId]) ?? []).findIndex(
            task => task.name === active.id,
        )

        const overIndex = ((overContainerReasonId && newMissingPlayers?.[overContainerReasonId]) ?? []).findIndex(
            task => task.name === over?.id,
        )

        if (activeIndex !== overIndex) {
            setNewMissingPlayers(prevMissingPlayers => ({
                ...prevMissingPlayers,
                ...(overContainerReasonId && {
                    [overContainerReasonId]: arrayMove(
                        (overContainerReasonId && prevMissingPlayers?.[overContainerReasonId]) ?? [],
                        activeIndex,
                        overIndex,
                    ),
                }),
            }))
        }
    }

    function handleDragEnd(event) {
        setCurrentActivePlayer(null)

        if (event.over) {
            const positionData = event.over.data.current
            const playerData = event.active.data.current
            const currentLineup = [...newLineup]
            const currentSubs = [...newSubs]

            const sortableOverContainerId = positionData.sortable?.containerId || event.over?.id
            const sortableActiveContainerId = playerData?.sortable?.containerId

            if (Object.values(MissingPlayerReasonAreaIds).includes(sortableOverContainerId)) {
                handleMissingPlayersSortable(event.over, event.active)

                // player is being dropped in sortable container
                // but it's not coming from a sortable container (Lineups or Subs)
                if (!sortableActiveContainerId) {
                    setLineupPlayer(playerData?.position?.rowInd, playerData?.position?.colInd)

                    setSubstitutionPlayer(playerData?.position?.index)
                }

                return
            }

            if (Object.values(MissingPlayerReasonAreaIds).includes(sortableActiveContainerId)) {
                setNewMissingPlayers(removePlayerFromList(newMissingPlayers, playerData?.player?.name))
            }

            let swappingPlayersWithSameNumber = false
            let isSubsPlayerSwappedWithLineupPlayer = false
            let isSubsPlayerSwappedWithSubsPlayer = false
            let shouldCheckShirtNumber = true

            const playerOnTheSamePositionInLineup = currentLineup
                .map((row, rowIndex) =>
                    row.map((player, colIndex) =>
                        rowIndex === positionData.rowInd && colIndex === positionData.colInd ? player : null,
                    ),
                )
                .flat()
                .find(player => player !== null)

            const playerOnTheSamePositionInSubs = currentSubs.find(
                (player, index) => index === positionData.index && player !== null,
            )

            const playerIsNotComingFromSquad = playerData?.player?.starter !== undefined

            if (playerOnTheSamePositionInLineup) {
                swappingPlayersWithSameNumber =
                    playerData?.player?.shirt_number === playerOnTheSamePositionInLineup?.shirt_number

                if (playerData.isSubstitution) {
                    shouldCheckShirtNumber = false

                    isSubsPlayerSwappedWithLineupPlayer = true

                    setSubstitutionPlayer(playerData?.position?.index, playerOnTheSamePositionInLineup)
                } else if (playerIsNotComingFromSquad) {
                    shouldCheckShirtNumber = false

                    setLineupPlayer(
                        playerData?.position?.rowInd,
                        playerData?.position?.colInd,
                        playerOnTheSamePositionInLineup,
                    )
                }
            }

            if (playerOnTheSamePositionInSubs) {
                swappingPlayersWithSameNumber =
                    playerData?.player?.shirt_number === playerOnTheSamePositionInSubs?.shirt_number

                if (!playerData.isSubstitution && playerIsNotComingFromSquad) {
                    shouldCheckShirtNumber = false

                    setLineupPlayer(
                        playerData?.position?.rowInd,
                        playerData?.position?.colInd,
                        playerOnTheSamePositionInSubs,
                    )
                }

                if (playerData.isSubstitution) {
                    isSubsPlayerSwappedWithSubsPlayer = true

                    shouldCheckShirtNumber = false

                    setSubstitutionPlayer(playerData?.position?.index, playerOnTheSamePositionInSubs, currentSubs)
                }
            }

            if (
                playerData?.player?.shirt_number === '-' ||
                (checkIsShirtNumberTaken(playerData?.player) &&
                    !swappingPlayersWithSameNumber &&
                    shouldCheckShirtNumber)
            ) {
                if (positionData.isSubstitution) {
                    setCurrentEditPlayer({ ...playerData?.player, index: positionData.index })
                } else {
                    setCurrentEditPlayer({
                        ...playerData?.player,
                        colIndex: positionData.colInd,
                        rowIndex: positionData.rowInd,
                    })
                }

                onOpenModal()
            } else if (positionData.isSubstitution) {
                setSubstitutionPlayer(positionData.index, playerData?.player, currentSubs)

                if (!isSubsPlayerSwappedWithSubsPlayer) {
                    filterSubs(playerData, positionData)
                }
            } else {
                setLineupPlayer(positionData.rowInd, positionData.colInd, playerData?.player)

                if (!isSubsPlayerSwappedWithLineupPlayer) {
                    filterSubs(playerData, positionData)
                }
            }

            if (currentLineup.flat().find(lineupPlayer => lineupPlayer?.name === playerData?.player?.name)) {
                const currentLineupWithoutActivePlayer = currentLineup.map((lineupRow, rowIndex) =>
                    lineupRow.map((player, columnIndex) =>
                        player?.name === playerData.player?.name &&
                        (positionData.rowInd !== rowIndex || positionData.colInd !== columnIndex)
                            ? null
                            : player,
                    ),
                )

                handleLineupChanges(currentLineupWithoutActivePlayer)
            }
        }
    }

    function handleDragStart(event) {
        setCurrentActivePlayer(event.active.data.current?.player)
    }

    function handleDragOver({ active, over }: DragOverEvent) {
        const sortableOverContainerId = over?.data?.current?.sortable?.containerId || over?.id
        const sortableActiveContainerId = active?.data?.current?.sortable?.containerId

        if (!Object.values(MissingPlayerReasonAreaIds).includes(sortableOverContainerId)) {
            if (over === null && !sortableActiveContainerId) {
                setNewMissingPlayers(prevMissingPlayers => {
                    if (!prevMissingPlayers) {
                        return {}
                    }

                    const activePlayerInMissingPlayers = Object.values(prevMissingPlayers)
                        .flat()
                        .find(
                            item =>
                                item.id === active?.data?.current?.player?.id ||
                                item.name === active?.data?.current?.player?.name,
                        )

                    return removePlayerFromList(prevMissingPlayers, activePlayerInMissingPlayers?.name)
                })
            }

            return
        }

        const activeContainerReasonId = getMissingPlayerReasonId(sortableActiveContainerId)
        const overContainerReasonId = getMissingPlayerReasonId(sortableOverContainerId)

        if (!sortableOverContainerId || sortableOverContainerId === sortableActiveContainerId) {
            return
        }

        setNewMissingPlayers(prevMissingPlayers => {
            const overContainerList = (overContainerReasonId && prevMissingPlayers?.[overContainerReasonId]) ?? []

            const activeContainerList = (activeContainerReasonId && prevMissingPlayers?.[activeContainerReasonId]) ?? []

            const overIndex = overContainerList.findIndex(task => task.name === over?.id)

            const activePlayerData = {
                ...extractBasicInfoFromPlayer(active?.data?.current?.player),
                ...{
                    source: active?.data?.current?.player?.source ?? 'manual',
                    reason: overContainerReasonId,
                    type: 0,
                },
            }

            const missingPlayersWithoutActive = removePlayerFromList(prevMissingPlayers, activePlayerData?.name)

            const overContainerListWithoutActive =
                (overContainerReasonId && missingPlayersWithoutActive[overContainerReasonId]) ?? []

            return {
                ...missingPlayersWithoutActive,
                ...(activeContainerReasonId && {
                    [activeContainerReasonId]: [...activeContainerList.filter(item => item.name !== active?.id)],
                }),
                ...(overContainerReasonId && {
                    [overContainerReasonId]: [
                        ...overContainerListWithoutActive.slice(0, overIndex),
                        activePlayerData,
                        ...overContainerListWithoutActive.slice(overIndex, overContainerListWithoutActive.length),
                    ],
                }),
            }
        })
    }

    const alertText = isLineupWithoutFormation
        ? {
              title: 'No formation provided!',
              description: 'This is the default formation. Please check the players!',
          }
        : {
              title: 'Previous match lineup!',
              description: 'This lineup is from the previous match. Please check the players!',
          }

    const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
    const sensors = useSensors(pointerSensor)

    const handleEditPlayer = player => {
        setIsExistingEditPlayer(true)

        setCurrentEditPlayer({ ...player.player, ...player.position })

        onOpenModal()
    }

    const handleAddNewPlayer = position => {
        setCurrentEditPlayer(position)

        onOpenModal()
    }

    const addPlayerToSquadList = playerData => {
        const currentAddedSquadPlayers = [...addedSquadPlayers]

        currentAddedSquadPlayers.push({ ...playerData.player })

        setAddedSquadPlayers(currentAddedSquadPlayers)
    }

    const discardChanges = () => {
        if (lineupData.match_id) {
            deleteLineupFromCache(lineupData.match_id)
        }

        const lineupInfo = getTeamData(initialLineup)

        setNewLineup(lineupInfo?.lineup)

        setNewSubs([
            ...lineupInfo.substitutions,
            ...new Array(Math.max(20 - lineupInfo.substitutions.length, 0)).fill(null),
        ])

        setNewFormation(lineupInfo?.formation)
    }

    return (
        <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            sensors={sensors}
            onDragOver={handleDragOver}>
            <Container maxW="container.xxl" p={{ base: 0, xl: 4 }}>
                {(isLineupWithoutFormation || isPreviousLineup) && (
                    <Alert status="warning" m={4}>
                        <AlertIcon />
                        <Box>
                            <AlertTitle>{alertText.title}</AlertTitle>
                            <AlertDescription>{alertText.description}</AlertDescription>
                        </Box>
                    </Alert>
                )}
                <Heading as="h2" size="md" mb={1} minH="10">
                    Create New Lineup
                </Heading>
                <Flex gap={4}>
                    <Box flex={{ base: 1, lg: 2 }} m={0}>
                        <Box
                            borderStyle="solid"
                            borderWidth="1px"
                            borderColor="blackAlpha.50"
                            borderRadius="16"
                            py={0}
                            px={{ base: 4, xl: 8 }}
                            ref={modalRef as LegacyRef<HTMLDivElement>}
                        >
                            <HStack mb={4} position="sticky" top="0" backgroundColor="white" zIndex={30} py={4}>
                                <FormationSelect
                                    newFormation={newFormation}
                                    currentLineup={newLineup}
                                    handleLineupChanges={handleLineupChanges}
                                    handleFormationChanges={handleFormationChanges}
                                />
                                <Button colorScheme="red" isDisabled={isLineupEmpty} onClick={deleteLineup}>
                                    Delete
                                </Button>
                                <Button
                                    colorScheme="grey"
                                    variant="outline"
                                    onClick={discardChanges}
                                    isDisabled={!hasUnsavedChanges}
                                >
                                    Discard
                                </Button>
                            </HStack>
                            {hasUnsavedChanges && (
                                <Alert status="warning" mb={4}>
                                    <AlertIcon />
                                    <Box>
                                        <AlertDescription>You have unsaved changes!</AlertDescription>
                                    </Box>
                                </Alert>
                            )}

                            <Wrap alignItems="flex-start" spacing={4} justify="center" mb={8}>
                                <WrapItem flex={1} display="flex" flexDir="column">
                                    <LineupPreviewHeader teamId={teamId} teamName={teamName} />
                                    {newFormation ? (
                                        <LineupEditor
                                            currentActivePlayer={currentActivePlayer}
                                            lineup={newLineup}
                                            formation={newFormation}
                                            isHomeTeam={isHomeTeam}
                                            handleEditPlayer={handleEditPlayer}
                                            handleAddPlayer={handleAddNewPlayer}
                                            handleLineupChanges={handleLineupChanges}
                                        />
                                    ) : (
                                        <LineupListEditor
                                            currentActivePlayer={currentActivePlayer}
                                            lineup={newLineup}
                                            isHomeTeam={isHomeTeam}
                                            handleEditPlayer={handleEditPlayer}
                                            handleAddPlayer={handleAddNewPlayer}
                                            handleLineupChanges={handleLineupChanges}
                                            showNoFormation={true}
                                        />
                                    )}
                                </WrapItem>
                                <WrapItem flex={1} display="flex" flexDir="column">
                                    <Text fontSize="sm" w="full" textAlign="end" mt="4" mb="5">
                                        Subs
                                    </Text>
                                    {newFormation !== undefined ? (
                                        <SubstitutionsEditor
                                            substitutions={newSubs}
                                            isHomeTeam={isHomeTeam}
                                            currentActivePlayer={currentActivePlayer}
                                            handleEditPlayer={handleEditPlayer}
                                            handleAddPlayer={handleAddNewPlayer}
                                            handleSubsChanges={handleSubsChanges}
                                        />
                                    ) : (
                                        <NoSubstitutions message="Select formation to start" />
                                    )}
                                </WrapItem>
                            </Wrap>
                        </Box>
                        <MissingPlayersEditor
                            missingPlayers={newMissingPlayers}
                            setMissingPlayers={setNewMissingPlayers}
                            currentActivePlayer={currentActivePlayer}
                            fixture={fixture}
                            isHomeTeam={isHomeTeam}
                        />
                    </Box>
                    <Box
                        flex={1}
                        py={5}
                        px={{ base: 4, xl: 8 }}
                        borderStyle="solid"
                        borderWidth="1px"
                        borderColor="blackAlpha.50"
                        borderRadius="16">
                        <SquadList
                            isHomeTeam={isHomeTeam}
                            teamId={teamId}
                            takenShirtNumbers={takenShirtNumbers}
                            currentActivePlayer={currentActivePlayer}
                            setAddedSquadPlayers={setAddedSquadPlayers}
                            addedSquadPlayers={addedSquadPlayers}
                            takenPlayers={newLineup
                                .flat()
                                .concat(newSubs, Object.values(newMissingPlayers ?? {}).flat())}
                        />
                    </Box>
                </Flex>
                <Modal
                    isOpen={isModalOpen}
                    onClose={onCloseModal}
                    size={modalSize}
                    scrollBehavior="outside"
                    returnFocusOnClose={false}>
                    <ModalOverlay />
                    <AddPlayerModal
                        takenShirtNumbers={takenShirtNumbers}
                        onAddNewPlayer={handleAddPlayer}
                        currentActivePlayer={currentEditPlayer}
                        isExistingPlayer={isExistingEditPlayer}
                    />
                </Modal>
            </Container>
            <DragOverlay>
                <ScaleFade in={!!currentActivePlayer} initialScale={0.8} reverse={true}>
                    <PlayerBasicInfo player={currentActivePlayer} isHomeTeam={isHomeTeam} isDragOverlay={true} />
                </ScaleFade>
            </DragOverlay>
        </DndContext>
    )
}
