import { ReactNode } from 'react'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Hide,
    IconButton,
    Show,
    useDisclosure,
    VStack,
} from '@chakra-ui/react'

type MatchMetaWrapperProps = {
    children?: ReactNode
}

export function CollapsableToDrawer({ children }: MatchMetaWrapperProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box onClick={event => event.stopPropagation()}>
            <Show above="xl">
                <Flex gap={4}>{children}</Flex>
            </Show>
            <Hide above="xl">
                <IconButton
                    aria-label="Add meta data"
                    icon={<HamburgerIcon />}
                    variant="outline"
                    onClick={onOpen}
                    isRound={true}
                    size="sm"
                />
                <Drawer onClose={onClose} isOpen={isOpen} size="sm">
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader borderBottomWidth="1px">Meta Data</DrawerHeader>
                        <DrawerBody my={6}>
                            <VStack>{children}</VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Hide>
        </Box>
    )
}
