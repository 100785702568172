import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'

import { useSearchState } from '@hooks/useSearchState'

import { baseStatsApiURL_NO_CACHE, directusBaseApiURLs } from '@common/config'
import { editorsList } from '@common/types'

import { useUser } from './useUser'

export const competitionListIds = {
    LINEUP_EDITOR: 136,
    MATCH_EVENT_EDITOR: 138,
    TOP_COMPETITIONS: 137,
} as const

export type TCompetitionListIds = (typeof competitionListIds)[keyof typeof competitionListIds]

async function handleResponse(response: Response) {
    if (response.status === 200) {
        return response.json()
    }

    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

async function fetchLineupEditorMyCompetitions(listId: TCompetitionListIds) {
    if (typeof listId === 'undefined') {
        return Promise.reject(new Error('No competition list id provided'))
    }

    const response = await fetch(`${directusBaseApiURLs.stage}/cached-superbet/mobile-widgets/${listId}`)

    return handleResponse(response)
}

async function fetchDataQualityMatches(dateFrom: string, accessToken?: string) {
    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    const response = await fetch(
        `${baseStatsApiURL_NO_CACHE}/soccer/data-quality-matches/comsuperbetsport/en-GB?from=${dateFrom}T00:00:00Z`,
        {
            headers: {
                Authentication: accessToken,
            },
        },
    )

    const data = await handleResponse(response)

    return data?.sort((a, b) => a.competition_name?.localeCompare(b.competition_name) || a.match_id - b.match_id)
}

export function useCompetitionsListData(editor = 'lineup') {
    const toast = useToast()
    const { userData } = useUser()

    const [{ date = new Date() }] = useSearchState()

    const formattedDate = format(date, 'yyyy-MM-dd')

    const {
        data: lineupCompetitions,
        isError: isLineupCompetitionError,
        error: lineupCompetitionsError,
    } = useQuery({
        queryKey: ['lineupCompetitions', competitionListIds.LINEUP_EDITOR],
        queryFn: async () => fetchLineupEditorMyCompetitions(competitionListIds.LINEUP_EDITOR),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })

    const {
        data: topCompetitions,
        isError: isTopCompetitionError,
        error: topCompetitionsError,
    } = useQuery({
        queryKey: ['topCompetitions', competitionListIds.TOP_COMPETITIONS],
        queryFn: async () => fetchLineupEditorMyCompetitions(competitionListIds.TOP_COMPETITIONS),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })

    const {
        data: matchEditorCompetitions,
        isError: isMatchEditorCompetitionError,
        error: matchEditorCompetitionError,
    } = useQuery({
        queryKey: ['matchEditorCompetitions', competitionListIds.MATCH_EVENT_EDITOR],
        queryFn: async () => fetchLineupEditorMyCompetitions(competitionListIds.MATCH_EVENT_EDITOR),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })

    const competitionsMap = {
        [editorsList.LINEUP_EDITOR]: lineupCompetitions,
        [editorsList.MATCH_EVENT_EDITOR]: matchEditorCompetitions,
    }

    const {
        data: dataQualityMatches,
        isError: isDataQualityMatchesError,
        isLoading: isDataQualityMatchesLoading,
        error: dataQualityMatchesError,
        refetch: refetchDataQualityMatches,
    } = useQuery({
        queryKey: ['eventsDataQuality', formattedDate, userData?.accessToken],
        queryFn: () => fetchDataQualityMatches(formattedDate, userData?.accessToken),
        enabled: !!date,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
    })

    useEffect(() => {
        if (isLineupCompetitionError) {
            toast({
                position: 'top',
                description: `Error fetching my competitions: ${lineupCompetitionsError}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [isLineupCompetitionError])

    useEffect(() => {
        if (isTopCompetitionError) {
            toast({
                position: 'top',
                description: `Error fetching top competitions: ${topCompetitionsError}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [isTopCompetitionError])

    useEffect(() => {
        if (isMatchEditorCompetitionError) {
            toast({
                position: 'top',
                description: `Error fetching my competitions: ${matchEditorCompetitionError}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [isMatchEditorCompetitionError])

    useEffect(() => {
        if (isDataQualityMatchesError) {
            toast({
                position: 'top',
                description: `Error fetching data quality matches: ${dataQualityMatchesError}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [isDataQualityMatchesError])

    return {
        competitionIds: {
            personal: competitionsMap[editor] ?? [],
            top: topCompetitions ?? [],
        },

        dataQualityMatches,
        isDataQualityMatchesLoading,
        refetchDataQualityMatches,
    }
}
