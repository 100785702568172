import { Box, Container, useToast } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'
import { components, GroupBase, MenuProps, OptionBase, Select } from 'chakra-react-select'

import { NewLineup } from '@common/types'

import { getLineup } from '@/common/utils'
import { useFormations } from '@/hooks/useFormations'

type Option = OptionBase & {
    value?: string
    label?: string
}

type GroupedOption = GroupBase<Option>

const MenuStyles = (base: CSSObject) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
})

function Menu(props: MenuProps<Option, false, GroupedOption>) {
    return (
        <components.Menu {...props}>
            <Box boxShadow="lg">{props.children}</Box>
        </components.Menu>
    )
}

type FormationSelectProps = {
    newFormation: string | null
    currentLineup: NewLineup
    handleLineupChanges: (lineup: NewLineup) => void
    handleFormationChanges: (formation: string | null) => void
}

const getEmptyLineupFromFormation = (formation: string): any[][] =>
    formation
        .split('-')
        .map(value => parseInt(value, 10))
        .reduce((acc, length) => [...acc, new Array(length).fill(null)], [[null]])

export function FormationSelect({
    newFormation,
    currentLineup,
    handleLineupChanges,
    handleFormationChanges,
}: FormationSelectProps) {
    const { formations, isLoading: isLoadingFormations } = useFormations()

    const toast = useToast()

    const handleSettingNewLineup = (formation: string | null) => {
        if (currentLineup?.flat().length) {
            const newFormationLineup = formation ? getLineup(currentLineup.flat(), formation).lineup : currentLineup

            handleLineupChanges(newFormationLineup)
        } else {
            const newEmptyLineup = formation
                ? getEmptyLineupFromFormation(formation)
                : [Array.from({ length: 11 }, () => null)]

            handleLineupChanges(newEmptyLineup)
        }
    }

    const handleFormationChange = (formation?: string) => {
        if (formation !== undefined) {
            const transformedFormation = formation?.length ? formation : null
            handleSettingNewLineup(transformedFormation)

            handleFormationChanges(transformedFormation)
        } else {
            toast({
                position: 'top',
                description: 'Missing formations!',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const options = formations?.formations.map(formation => ({
        label: formation,
        value: formation,
    }))

    options?.unshift({
        label: 'No formation',
        value: '',
    })

    return (
        <Container maxW="container.xxl" position="relative" zIndex={50} px={0}>
            <Select
                components={{ Menu }}
                styles={{ menu: MenuStyles }}
                value={
                    newFormation ? { label: newFormation, value: newFormation } : { label: 'No formation', value: '' }
                }
                placeholder="Select formation to start..."
                onChange={e => handleFormationChange(e?.value)}
                isLoading={isLoadingFormations}
                options={options}
            />
        </Container>
    )
}
