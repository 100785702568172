/* eslint-disable @typescript-eslint/naming-convention */
import { z, ZodIssueCode } from 'zod'

export interface TPlayer {
    id?: string | null
    name?: string
    shirt_number?: number | '-'
    order?: number | null
    starter?: boolean
}

export interface FixtureLineup {
    team2_lineup: TPlayer[]
    team2_formation: string | null
    team2_id?: string | null

    team1_id?: string | null
    team1_lineup: TPlayer[]
    team1_formation: string | null

    match_id: string | null
    match_status: number | null
}

export type TTeamLineupData = {
    formation: string | null
    lineup: TPlayer[][]
    substitutions: TPlayer[]
}

export interface CurrentActivePlayer {
    id?: TPlayer['id']
    name?: TPlayer['name']
    shirt_number?: TPlayer['shirt_number']
    order?: TPlayer['order']
    starter?: TPlayer['starter']
    rowIndex?: number
    colIndex?: number
    index?: number
}

export type NewLineup = (TPlayer | null)[][]
export type NewSubs = TPlayer[] | any[]

export const MatchEventEditorEventType = {
    goal: 30,
    yellow_card: 40,
    yellowred_card: 45,
    red_card: 50,
    substitution: 60,
    // injury_time: 90,
    // free_kick: 150,
    // goal_kick: 151,
    // throw_in: 152,
    // offside: 153,
    // corner: 154,
    // shot_on_target: 155,
    // shot_off_target: 156,
    // goalkeeper_save: 157,
    // injury: 158,
    // penalty_awarded: 161,
    // shot_blocked: 172,
    // penalty_missed: 666,
    // penalty_shootout_event_deep_coverage: 1002,
    // penalty_shootout_starting_team: 1104,
    var_over: 2065,
} as const

export const MatchEventEditorEventTypeEnum = z.nativeEnum(MatchEventEditorEventType)

export type TMatchEventEditorEventType = z.infer<typeof MatchEventEditorEventTypeEnum>

export const MatchEventEditorGoalExtraInfo = {
    free_kick: -200,
    own_goal: 2,
    penalty: 1,
    header: 3,
    shot: -100,
} as const

export const MatchEventEditorGoalExtraInfoEnum = z.nativeEnum(MatchEventEditorGoalExtraInfo)

export type TMatchEventEditorGoalExtraInfo = z.infer<typeof MatchEventEditorGoalExtraInfoEnum>

export const MatchEventEditorCardExtraInfo = {
    in_play: 0,
    bench_player: 1,
    manager: 2,
    staff: 3,
} as const

export const MatchEventEditorCardExtraInfoEnum = z.nativeEnum(MatchEventEditorCardExtraInfo)

export type TMatchEventEditorCardExtraInfo = z.infer<typeof MatchEventEditorCardExtraInfoEnum>

export const MatchEventEditorVarExtraInfo = {
    goal: 0,
    no_goal: 1,
    penalty: 2,
    no_penalty: 3,
    red_card: 4,
    no_red_card: 5,
} as const

export const MatchEventEditorVarExtraInfoEnum = z.nativeEnum(MatchEventEditorVarExtraInfo)

export type TMatchEventEditorVarExtraInfo = z.infer<typeof MatchEventEditorVarExtraInfoEnum>

export const MatchEventEditorEventModificationType = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
}

export type TMatchEventEditorEventModificationType =
    (typeof MatchEventEditorEventModificationType)[keyof typeof MatchEventEditorEventModificationType]

export type MatchEventEditorEventMatchScore = {
    team1: number
    team2: number
}

export const MatchEventEditorEventFeedProvider = {
    LIVESCOUT: 'livescout',
    OPTA: 'opta',
    SUPEROLOGY_SOCCER: 'superology_soccer',
}

export type TMatchEventEditorEventFeedProvider =
    (typeof MatchEventEditorEventFeedProvider)[keyof typeof MatchEventEditorEventFeedProvider]

export type MatchEventEditorApiEvent = {
    type: TMatchEventEditorEventType
    time: string
    team_id: string
    player1_id: string
    player2_id: string
    modification_type: TMatchEventEditorEventModificationType | null
    match_time: string
    timestamp: string
    match_score?: MatchEventEditorEventMatchScore
    info: string
    id: number
    feed_provider: TMatchEventEditorEventFeedProvider
    extra_info: number
    date_modified: string
    team1_score?: number
    team2_score?: number
    period_number?: number
}

export type EnrichedMatchEditorApiEvent = MatchEventEditorApiEvent & {
    team_name?: string
    player1_name?: string
    player2_name?: string
}

export type MatchEventEditorEvent = {
    stime: string
    side: number
    remaining_time_period?: unknown
    possible_event_match_time?: unknown
    pos_y?: unknown
    pos_x?: unknown
    period_number?: unknown
} & MatchEventEditorApiEvent

export type MatchEventEditorTeamInfo = {
    sport_id: number
    short_code: {
        value: string
    }
    name: string
    id: string
    country_code: {
        value: string
    }
    features: number[]
}

export const MatchStatus = {
    // Live
    started: 20,
    a1st_half: 6,
    a2nd_half: 7,
    halftime: 31,
    awaiting_extra_time: 32,
    extra_time_halftime: 33,
    awaiting_penalties: 34,
    a1st_extra: 41,
    a2nd_extra: 42,
    penalties: 50,
    overtime: 40,
    // Other
    postponed: 60,
    cancelled: 70,
    interrupted: 80,
    coverage_cancelled: 81,
} as const

export const MatchStatusEnum = z.nativeEnum(MatchStatus)

export type TMatchStatus = z.infer<typeof MatchStatusEnum>

export type MatchEventEditorGetDataResponse = {
    id: string
    team1_id: string
    team2_id: string
    team1_info?: MatchEventEditorTeamInfo
    team2_info?: MatchEventEditorTeamInfo
    match_status: TMatchStatus
    date: string
    goals: MatchEventEditorApiEvent[]
    substitutions: MatchEventEditorApiEvent[]
    yellow_cards: MatchEventEditorApiEvent[]
    yellow_red_cards: MatchEventEditorApiEvent[]
    red_cards: MatchEventEditorApiEvent[]
    var_outcomes: MatchEventEditorApiEvent[]
}

const Option = z.object({
    value: z.coerce.string(),
    label: z.coerce.string(),
})

const ZMatchTime = z.object({
    minutes: z.coerce.number(),
    seconds: z.coerce.number(),
    additionalMinutes: z.coerce.string(),
})

export type MatchTime = z.infer<typeof ZMatchTime>

export const MatchEventPeriodNumber = {
    first_half: 1,
    second_half: 2,
    first_half_extra_time: 3,
    second_half_extra_time: 4,
    penalties: 5,
} as const

export const MatchEventPeriodNumberEnum = z.nativeEnum(MatchEventPeriodNumber)

export type TMatchEventPeriodNumber = z.infer<typeof MatchEventPeriodNumberEnum>

export const EventSchema = z
    .object({
        player1_id: Option.optional(),
        player2_id: Option.optional(),
        team_id: Option.required().refine(({ value }) => !!value, { message: 'Team ID must be provided' }),
        match_time: ZMatchTime.required().refine(
            ({ minutes, seconds }) => minutes >= 0 && minutes <= 120 && seconds >= 0 && seconds <= 59,
            {
                message: 'Valid match time must be provided',
            },
        ),
        type: Option.required().refine(({ value }) => !!value, { message: 'Event type must be selected' }),
        extra_info: z.union([z.coerce.number(), z.nan()]).optional().default(-100),
        team1_score: z.coerce.number().optional().default(0),
        team2_score: z.coerce.number().optional().default(0),
        period_number: Option.required().refine(({ value }) => !!value, { message: 'Period must be selected' }),
        player1_name: z.string().optional(),
        player2_name: z.string().optional(),
    })
    .superRefine(({ type, player1_id, player2_id, team1_score, team2_score }, refinementContext) => {
        if (Number(type.value) === MatchEventEditorEventType.goal) {
            // Selecting a player for match events was mandatory until now.
            // There is a possibility that sometimes we do not know which player received a yellow card,
            // but we will know that a yellow card happened
            /* if (player1_id?.value?.length === 0) {
                refinementContext.addIssue({
                    code: ZodIssueCode.custom,
                    message: 'Goalscorer must be selected',
                    path: ['player1_id'],
                })
            } */

            if (team1_score === 0 && team2_score === 0) {
                refinementContext.addIssue({
                    code: ZodIssueCode.custom,
                    message: 'Team score must be provided',
                    path: ['team1_score', 'team2_score'],
                })
            }
        }

        if (Number(type.value) === MatchEventEditorEventType.substitution) {
            // Selecting a player for match events was mandatory until now.
            // There is a possibility that sometimes we do not know which player received a yellow card,
            // but we will know that a yellow card happened
            /* if (player1_id?.value?.length === 0) {
                refinementContext.addIssue({
                    code: ZodIssueCode.custom,
                    message: 'Players to be substituted must be selected',
                    path: ['player1_id'],
                })
            }

            if (player1_id?.value?.length === 0) {
                refinementContext.addIssue({
                    code: ZodIssueCode.custom,
                    message: 'Players to be substituted must be selected',
                    path: ['player1_id'],
                })
            } */
        }

        if (
            Number(type.value) === MatchEventEditorEventType.yellow_card ||
            Number(type.value) === MatchEventEditorEventType.red_card ||
            Number(type.value) === MatchEventEditorEventType.yellowred_card
        ) {
            // Selecting a player for match events was mandatory until now.
            // There is a possibility that sometimes we do not know which player received a yellow card,
            // but we will know that a yellow card happened
            /* if (player1_id?.value?.length === 0) {
                refinementContext.addIssue({
                    code: ZodIssueCode.custom,
                    message: 'Player to be booked must be selected',
                    path: ['player1_id'],
                })
            } */
        }

        return refinementContext
    })

export type EventFormData = z.infer<typeof EventSchema> & {
    id?: string
}

export type MatchEventEditorPostDataRequest = {
    player1_id?: string | null
    player2_id?: string | null
    player1_name?: string | null
    player2_name?: string | null
    team_id: string
    match_time: string
    timestamp: string
    type: number
    extra_info?: number
    team1_score?: number
    team2_score?: number
    period_number: number
}

export type MatchEventEditorPostDataResponse = {
    match_id: string
    event: MatchEventEditorEvent
}

export type MatchEventEditorPutDataRequest = MatchEventEditorPostDataRequest & {
    id: string
}

export type MatchEventEditorPutDataResponse = {
    match_id: string
    event: MatchEventEditorEvent
}

export type MatchEventEditorDeleteDataResponse = {
    match_id: string
    event: MatchEventEditorEvent
}

export type TeamLineup = {
    captain: boolean
    formation_place: number
    name: string
    player_id: string
    position: number
    position_side: string
    shirt_number: number
    substitute: boolean
}

export type MatchLineup = {
    id: string
    team1_lineup: TeamLineup[]
    team2_lineup: TeamLineup[]
}

export type MatchLineupData = {
    team1Lineup: TeamLineup[]
    team2Lineup: TeamLineup[]
}

export type MatchLineupDataResponse = {
    id?: string
    lineups: MatchLineup
}

export const ApiErrorMessage = {
    UNAUTHORIZED: 'Unauthorized',
}

export const ApiErrorMessageEnum = z.nativeEnum(ApiErrorMessage)

export type TApiErrorMessage = z.infer<typeof ApiErrorMessageEnum>

export const editorsList = {
    LINEUP_EDITOR: 'lineups',
    MATCH_EVENT_EDITOR: 'match-events',
} as const

export type TEditorsList = (typeof editorsList)[keyof typeof editorsList]

export const StateEnum = {
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
} as const

export type TStateEnum = (typeof StateEnum)[keyof typeof StateEnum]

export const ReasonEnum = {
    NO_SOURCE: 'no_source',
    LATE_SOURCE: 'late_source',
    BUG: 'bug',
    NO_CAPACITY: 'no_capacity',
} as const

export type TReasonEnum = (typeof ReasonEnum)[keyof typeof ReasonEnum]

export const ModifiedInEnum = {
    PRE_MATCH: 'pre_match',
    LIVE: 'live',
    POST_MATCH: 'post_match',
} as const

export type TModifiedInEnum = (typeof ModifiedInEnum)[keyof typeof ModifiedInEnum]

export const LockingActionTypeEnum = {
    LOCK: 'lock',
    UNLOCK: 'unlock',
} as const

export type LockingActionType = (typeof LockingActionTypeEnum)[keyof typeof LockingActionTypeEnum]

export type LockingAction = {
    type: LockingActionType
    match_time: string
    period_number: number
}

export type MetaData = {
    state?: TStateEnum | null
    reason?: TReasonEnum | null
    comment?: string | null
    modified_in?: TModifiedInEnum | null
    date_modified?: string | null
    locking_action?: LockingActionType | null
    locking_actions?: LockingAction[] | null
}

export type CompetitionListMatch = {
    competition_name: string
    match_name: string
    match_id: string
    match_time: string
    meta_data: {
        reason?: TReasonEnum
        state?: TStateEnum
        comment?: string
    }
}

export const MissingPlayerReason = {
    MISSINGPLAYERREASON_UNKNOWN: 0,
    MISSINGPLAYERREASON_INJURY: 1,
    MISSINGPLAYERREASON_SUSPENSION: 2,
} as const

export const MissingPlayerReasonAreaIds = {
    [MissingPlayerReason.MISSINGPLAYERREASON_INJURY]: 'injured-players',
    [MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION]: 'suspended-players',
}

export type TMissingPlayerReason = (typeof MissingPlayerReason)[keyof typeof MissingPlayerReason]

export type TMissingPlayerReasonAreaIds = (typeof MissingPlayerReasonAreaIds)[keyof typeof MissingPlayerReasonAreaIds]

export type MissingPlayer = {
    type: number
    source: string | null
    reason: TMissingPlayerReason
    id: string | null
    name: string
    shirt_number: number | '-'
}

export type MissingPlayersList = {
    [key in TMissingPlayerReason]?: MissingPlayer[]
}

type MissingPlayerPostDataRequest = {
    id: null | string
    name: string
    shirt_number: string
    type: number
    source: 'provider' | 'manual'
}

export type MissingPlayersPostDataRequest = {
    match_id: string
    team1_id: string
    team2_id: string
    team1_injuries: MissingPlayerPostDataRequest[]
    deleted_team1_injuries: string[]
    team1_suspensions: MissingPlayerPostDataRequest[]
    deleted_team1_suspensions: string[]
    team2_injuries: MissingPlayerPostDataRequest[]
    deleted_team2_injuries: string[]
    team2_suspensions: MissingPlayerPostDataRequest[]
    deleted_team2_suspensions: string[]
}

type BaseInfo = {
    country_code: string | null
    id: string | null
    name: string | null
}

export type MatchDetails = {
    category: BaseInfo | null
    competition: BaseInfo | null
    date: string | null
    features: number[] // add feature types here
    id: string
    lineups_editor_meta: MetaData | null
    match_events_editor_meta: MetaData | null
    match_status: number | null
    season: BaseInfo | null
    team1: BaseInfo | null
    team2: BaseInfo | null
}
