import { ReactNode } from 'react'
import { useDraggable } from '@dnd-kit/core'

import { TPlayer } from '@/common/types'

type DraggableProps = {
    children: ReactNode
    player: TPlayer
    position: {
        index?: number
        colIndex?: number
        rowIndex?: number
    }
    isSubstitution: boolean
}
export function Draggable({ children, player, position, isSubstitution }: DraggableProps) {
    const { attributes, listeners, setNodeRef } = useDraggable({
        id: `${player.name}-${player.id}`,
        data: { player, position, isSubstitution },
    })

    return (
        <div ref={setNodeRef} {...attributes} {...listeners}>
            {children}
        </div>
    )
}
