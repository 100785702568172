import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useRouter } from '@tanstack/react-router'

import { useSearchState } from '@hooks/useSearchState'

import { TEditorsList } from '@common/types'

import { CompetitionTableWithFilters } from './CompetitionTableWithFilters'

type CompetitionListProps = {
    editor: TEditorsList
}

export function CompetitionLists({ editor }: CompetitionListProps) {
    const router = useRouter()
    const [searchState] = useSearchState()

    const handleTabChange = (index: number) => {
        switch (index) {
            case 0:
                router.navigate({ to: `/editors/${editor}/todo`, search: searchState })

                break

            case 1:
                router.navigate({ to: `/editors/${editor}/completed`, search: searchState })

                break

            default:
                break
        }
    }

    const getIndexByPath = () => {
        switch (router.state.location.pathname) {
            case `/editors/${editor}/todo`:
                return 0

            case `/editors/${editor}/completed`:
                return 1

            default:
                return 0
        }
    }

    return (
        <div>
            <Tabs index={getIndexByPath()} onChange={handleTabChange} mx={{ base: 0, xl: -12 }}>
                <TabList>
                    <Tab>To do</Tab>
                    <Tab>Completed</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <CompetitionTableWithFilters editor={editor} />
                    </TabPanel>
                    <TabPanel>
                        <CompetitionTableWithFilters editor={editor} isCompleted={true} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    )
}
