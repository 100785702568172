import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { baseStatsApiURL_NO_CACHE } from '@common/config'
import { ApiErrorMessage, MatchLineupData, MatchLineupDataResponse } from '@common/types'

import { useUser } from '@queries/useUser'

function extractMatchLineupData(data: MatchLineupDataResponse): MatchLineupData {
    if (!data) {
        return {
            team1Lineup: [],
            team2Lineup: [],
        }
    }

    const team1Lineup = data.lineups?.team1_lineup ?? []
    const team2Lineup = data.lineups?.team2_lineup ?? []

    return {
        team1Lineup,
        team2Lineup,
    }
}

async function fetchMatchLineup(id?: string, accessToken?: string): Promise<MatchLineupDataResponse> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    const response = await fetch(`${baseStatsApiURL_NO_CACHE}/default/data-provider/api/formation?match_id=${id}`, {
        headers: {
            Authentication: accessToken,
        },
    })

    if (response.status === 200) {
        return response.json()
    }

    if (response.status === 401) {
        return Promise.reject(new Error(ApiErrorMessage.UNAUTHORIZED))
    }

    // @TODO: Will Čačko ever add some error responses to the API?
    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

export function useMatchLineup(id?: number | string | null, options: Pick<UseQueryOptions, 'enabled'> = {}) {
    const { userData } = useUser()

    const matchLineupQuery = useQuery<MatchLineupDataResponse, Error, MatchLineupData>({
        enabled: !!id && !!userData?.accessToken,
        ...(options as Pick<UseQueryOptions<MatchLineupDataResponse, Error, MatchLineupData>, 'enabled'>),
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ['match', 'lineup', String(id)],
        queryFn: () => fetchMatchLineup(String(id), userData?.accessToken),
        select: extractMatchLineupData,
    })

    return matchLineupQuery
}
