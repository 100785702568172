import { Container } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

import { ReasonEnum, TReasonEnum } from '@common/types'

type ReasonSelectProps = {
    reason?: TReasonEnum | null
    handleReasonChange: (reason: TReasonEnum | null) => void
}
export function ReasonSelect({ reason, handleReasonChange }: ReasonSelectProps) {
    const options = Object.values(ReasonEnum)
        .map(value => ({
            value: value as TReasonEnum | null,
            label: value.replace('_', ' '),
        }))
        .concat({
            value: null,
            label: 'no reason',
        })

    const changeReason = event => {
        if (reason !== event?.value) {
            handleReasonChange(event?.value)
        }
    }

    return (
        <Container maxW="sm" m={0} p={0}>
            <Select
                placeholder="No reason"
                onChange={e => {
                    changeReason(e)
                }}
                options={options}
                size="sm"
                value={{ label: reason?.replace('_', ' ') ?? 'no reason', value: reason }}
            />
        </Container>
    )
}
