import { useRef, useSyncExternalStore } from 'react'

function isShallowEqual(object1: Record<string, unknown>, object2: Record<string, unknown>) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    keys1.forEach(key => object1[key] !== object2[key])

    return true
}

function getConnection() {
    return (navigator as any)?.connection || (navigator as any)?.mozConnection || (navigator as any)?.webkitConnection
}

function useNetworkStateSubscribe(callback: (this: Window, ev: Event) => any) {
    window.addEventListener('online', callback, { passive: true })

    window.addEventListener('offline', callback, { passive: true })

    const connection = getConnection()

    if (connection) {
        connection.addEventListener('change', callback, { passive: true })
    }

    return () => {
        window.removeEventListener('online', callback)

        window.removeEventListener('offline', callback)

        if (connection) {
            connection.removeEventListener('change', callback)
        }
    }
}

type NetworkState = {
    online?: boolean
    downlink?: number
    downlinkMax?: number
    effectiveType?: string
    rtt?: number
    saveData?: boolean
    type?: string
}

export function useNetworkState() {
    const cache = useRef<NetworkState>({})

    const getSnapshot = () => {
        const online = navigator.onLine

        const connection = getConnection()

        const nextState = {
            online,
            downlink: connection?.downlink,
            downlinkMax: connection?.downlinkMax,
            effectiveType: connection?.effectiveType,
            rtt: connection?.rtt,
            saveData: connection?.saveData,
            type: connection?.type,
        }

        if (isShallowEqual(cache.current as Record<string, unknown>, nextState)) {
            return cache.current
        }

        cache.current = nextState

        return nextState as NetworkState
    }

    return useSyncExternalStore(useNetworkStateSubscribe, getSnapshot, () => null)
}
