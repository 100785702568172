import { Box, Flex } from '@chakra-ui/react'

import { MissingPlayer } from '@common/types'

interface ListPlayerBasicInfoProps {
    player: MissingPlayer
    handlePlayerClick?: (player: MissingPlayer) => void
}

export function ListPlayerBasicInfo({ player, handlePlayerClick }: ListPlayerBasicInfoProps) {
    const onPlayerClick = () => handlePlayerClick && handlePlayerClick(player)

    return (
        <Flex p={3} gap={3} alignItems="center" wrap="nowrap" onClick={onPlayerClick}>
            <Box
                p={2}
                color="sp.white"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="blackAlpha.50"
                bgColor="sp.mirage"
                height={8}
                width={8}
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="900"
                fontSize="sm"
                borderRadius={100}
            >
                {player?.shirt_number}
            </Box>
            <Box as="span">{player?.name}</Box>
        </Flex>
    )
}
