import { QueryClientConfig } from '@tanstack/react-query'

export const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            retry: 2,
            retryDelay: attemptIndex => Math.min(1000 * 5 ** attemptIndex, 30000),
        },
    },
}
