import { VStack } from '@chakra-ui/react'
import { Route } from '@tanstack/react-router'

import { LineupAndSubsPreviewTabs } from '@components/LineupEditor/LineupAndSubsPreviewTabs'

import { useSearchState } from '@hooks/useSearchState'

import { LineupDataProvider } from '@contexts/LineupDataContext'

import { appIndex } from './App'

function LineupsEditor() {
    const [{ matchId: selectedMatchId }] = useSearchState()

    if (selectedMatchId) {
        return (
            <LineupDataProvider>
                <VStack justifyContent="center" alignItems="center" direction="column" spacing={6} mb={10} w="100%">
                    <LineupAndSubsPreviewTabs />
                </VStack>
            </LineupDataProvider>
        )
    }
}

export const lineupsEditor = new Route({
    getParentRoute: () => appIndex,
    path: 'editors/lineups',
    component: LineupsEditor,
})
