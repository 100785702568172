import { Box, HStack, Text } from '@chakra-ui/react'

import { EntityImage } from '@components/EntityImage'
import { Icon } from '@components/Icon'

interface LineupPreviewHeaderProps {
    teamId?: string | null
    teamName: string | null | undefined
}

export function LineupPreviewHeader({ teamId, teamName }: LineupPreviewHeaderProps) {
    return (
        <HStack justify="space-between" w="full" mb={2}>
            <HStack spacing={2} justifyContent="end">
                <Box p={1} bg="sp.grey" borderRadius="50%" flexShrink={0}>
                    <Box borderRadius="inherit" bg="sp.white" shadow="md" p={2}>
                        <EntityImage
                            entityId={{ id: teamId }}
                            boxSize="1.5rem"
                            fallback={<Icon type="Shield" boxSize="1.5rem" />}
                        />
                    </Box>
                </Box>
                <Text fontSize="sm" textAlign="end" noOfLines={2}>
                    {teamName}
                </Text>
            </HStack>
            <Text fontSize="sm">Starting XI</Text>
        </HStack>
    )
}
