const variants = {
    responsive: {
        baseStyle: {
            popper: {
                width: 'fit-content',
                maxWidth: 'fit-content',
            },
        },
    },
}

export const Popover = {
    variants,
}
