/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Amplify, type ResourcesConfig } from 'aws-amplify'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { CookieStorage } from 'aws-amplify/utils'

import './styles/index.css'
import { App } from './App'
import prodAwsExports from './aws-exports.prod'
import stageAwsExports from './aws-exports.stage'
import { getIsStageEnv } from './common/utils'

const awsExports = getIsStageEnv() ? stageAwsExports : prodAwsExports

const authConfig: ResourcesConfig['Auth'] = {
    Cognito: {
        userPoolId: awsExports.USER_POOL_ID,
        userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
        loginWith: {
            oauth: {
                domain: awsExports.DOMAIN,
                scopes: ['openid email profile aws.cognito.signin.user.admin '],
                redirectSignIn: [awsExports.REDIRECT_SIGN_IN],
                redirectSignOut: [awsExports.REDIRECT_SIGN_IN],
                responseType: 'code',
            },
        },
    },
}

Amplify.configure({
    Auth: authConfig,
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage())

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)
