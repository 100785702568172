import { Flex } from '@chakra-ui/react'

import { Footer } from './Footer'
import { Main } from './Main'
import { Navbar } from './Navbar'

export function Shell() {
    return (
        <Flex direction="column" flex="1" bg="transparent">
            <Navbar />
            <Main />
            <Footer />
        </Flex>
    )
}
