import { SVGProps } from 'react'

export default function SvgPlayerPlaceholder(props: SVGProps<SVGSVGElement>) {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
            <mask
                id="player-placeholder_svg__a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={300}
                height={300}>
                <circle cx={150} cy={150} r={150} fill="#F1EFF4" />
            </mask>
            <g mask="url(#player-placeholder_svg__a)">
                <circle cx={150} cy={150} r={150} fill="#F1EFF4" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M152.996 168.003c29.824 0 54.001-24.177 54.001-54.002C206.997 84.177 182.82 60 152.996 60c-29.825 0-54.002 24.177-54.002 54.001 0 29.825 24.177 54.002 54.002 54.002zm.001 13.491c-71.797 0-121.503 57.117-121.503 102.811 0 45.693 27 45.693 121.503 45.693s121.504 0 121.504-45.693c0-45.694-49.706-102.811-121.504-102.811z"
                    fill="#AAA6B5"
                />
            </g>
        </svg>
    )
}
