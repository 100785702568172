import { Button, Center, forwardRef, VStack } from '@chakra-ui/react'

import { Icon } from '@components/Icon'

interface EmptyPlayerProps {
    onEmptyPlayerClick: (position) => void
    id?: string
    activeDroppable?: any | null
    colInd?: number
    rowInd?: number
    index?: number
    isSubstitution?: boolean
}

export const EmptyPlayer = forwardRef(
    ({ onEmptyPlayerClick, id, activeDroppable, colInd, rowInd, isSubstitution, index }: EmptyPlayerProps, ref) => {
        const variant = activeDroppable?.id === id ? 'over' : 'mirage'

        const handleClick = () => {
            if (isSubstitution) {
                onEmptyPlayerClick({ index })
            } else {
                onEmptyPlayerClick({ colIndex: colInd, rowIndex: rowInd })
            }
        }

        return (
            <VStack
                spacing={0.5}
                alignItems="center"
                w="3.8rem"
                h="4.5rem"
                ref={ref}
                cursor="pointer"
                onClick={handleClick}
                justifyContent="center">
                <Center
                    boxSize="2rem"
                    borderColor="sp.mirage"
                    borderWidth="1px"
                    borderRadius="full"
                    borderStyle="dashed"
                >
                    <Button
                        size="xs"
                        aria-label="Search database"
                        variant={variant}
                        p={0}
                        borderRadius="full"
                        transition="opacity 0.5s, backgroundColor 0.5s"
                    >
                        <Icon type="Plus" boxSize="1.25rem" />
                    </Button>
                </Center>
            </VStack>
        )
    }
)
