import { useState } from 'react'
import { ChatIcon } from '@chakra-ui/icons'
import {
    Button,
    FormControl,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react'

type CommentProps = {
    comment?: string | null
    handleSendComment: (comment: string) => void
}
export function Comment({ comment, handleSendComment }: CommentProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [newComment, setNewComment] = useState(comment ?? '')

    const handleChange = event => {
        setNewComment(event?.target?.value)
    }

    const sendComment = () => {
        handleSendComment(newComment)

        onClose()
    }

    return (
        <>
            <Tooltip hasArrow label={comment} isDisabled={!comment} placement="top">
                <IconButton
                    onClick={onOpen}
                    variant={comment ? 'solid' : 'outline'}
                    icon={<ChatIcon />}
                    size="sm"
                    aria-label="Add/Edit comment"
                />
            </Tooltip>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add comment</ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <Input placeholder="Comment" value={newComment} onChange={handleChange} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="mirage"
                            mr={3}
                            isDisabled={!newComment || comment === newComment}
                            onClick={sendComment}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
